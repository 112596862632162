import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../Tools/Inputs/input";
import AssetImg from "../AssetImg/AssetImg";
import "./SpecialLink.css";
import {
  CreateFolderName,
  PlayableData,
} from "../DesignAppStepsComponents/DesignApp";
import { SpecialLinkLable, SpecialLinkPlaceholder } from "../../../Texts/Text";
const SpecialLink = (props) => {
  const MainUrl = useContext(CreateFolderName);
  const ThePlayable = useContext(PlayableData);

  const [GamePreviewLink, setGamePreviewLink] = useState();
  useEffect(() => {
    setGamePreviewLink(
      "https://assets.wackotech.com/purchased_playable//" + MainUrl.mainFolder
    );
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <>
        {ThePlayable.landScape === true ? (
          <AssetImg
            LandScape={ThePlayable.landScape}
            src={GamePreviewLink}
            EditScroll={true}
            EditSctollText={t("Edit_Link")}
          />
        ) : null}
        <div
          className={
            ThePlayable.landScape === true
              ? "col-11 auto p-1"
              : "col-lg-9 col-md-6  p-1 pt-5"
          }
        >
          <div className="pt-1 pb-0 p-4 special-link-box">
            <p>{t(SpecialLinkLable)}</p>
            <div>
              <Field
                Id="SpecialLink"
                type="text"
                placeholder={t(SpecialLinkPlaceholder)}
              />
            </div>
          </div>
          {ThePlayable.landScape === true ? (
            <div className="col-12 pt-2">
              <div className=" redirect-btn mb-0 ">
                {props.LastPage}
                {props.NextStep}
              </div>
            </div>
          ) : (
            <div className="redirect-btn mt-5">
              {props.LastPage}
              {props.NextStep}
            </div>
          )}
        </div>

        {ThePlayable.landScape === false ? (
          <AssetImg LandScape={ThePlayable.landScape} src={GamePreviewLink} />
        ) : null}
      </>
    </>
  );
};

export default SpecialLink;
