import React, { useState, useEffect } from "react";
import {
  BaseUrl,
  GetTicketRequest,
  UpadateTicketRequest,
} from "../../../Texts/ApiUrl";
import { CallApi } from "../../CallApi/CallApi";
import Loading from "../../../Tools/Loading/Loading";
import TextArea from "../../../Tools/TextArea/TextArea";
import {
  ReplayTicketTextAreaPlaceholder,
  error,
  EmptyTicketMessage,
  TicketsListTextBtn,
  TicketMenuItemText,
  verificationBtnText,
  PlaceholderMessage,
} from "../../../Texts/Text";
import ErrorPopUp from "../../../ErrorPopUp/ErrorPopUp";
import "./UpdateTicket.css";
import ScrollToBottom from "react-scroll-to-bottom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import LeftNav from "../../LeftNav/LeftNav";

const UpdateTicket = () => {
  const search = window.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  var Ticketid = params.get("id"); // bar
  useEffect(() => {
    console.log(localStorage.getItem("UserToken"));
    const theCookie = Cookies.get("i18next");
    setlang(theCookie);
    const LoadImportantData = async () => {
      let ticketData = await GetTicket(Ticketid);
      /*  let AllMessage = [];
      for (var i = 0; i < ticketData.ticketData.length; i++) {
        let Md = SetDate(ticketData.ticketData[i].date);
        let GroupMessage = {};
        GroupMessage["Date"] = Md;
        GroupMessage["Message"] = ticketData.ticketData[i];
        AllMessage.push(GroupMessage);
      } */

      let Date = SetDate(ticketData.ticketData[0].date);
      let NewAllMessage = [];
      var Messages = [];
      let GroupMessage = {};
      /*  console.log(Date); */
      GroupMessage["Date"] = ticketData.ticketData[0].date;
      for (var i = 0; i < ticketData.ticketData.length; i++) {
        let Md = SetDate(ticketData.ticketData[i].date);
        if (Md === Date) {
          Messages.push(ticketData.ticketData[i]);
        } else {
          GroupMessage["AllMessage"] = Messages;
          NewAllMessage.push(GroupMessage);
          Messages = [];
          GroupMessage = {};
          Messages.push(ticketData.ticketData[i]);
          Date = SetDate(ticketData.ticketData[i].date);
          GroupMessage["Date"] = ticketData.ticketData[i].date;
        }
      }

      GroupMessage["AllMessage"] = Messages;
      NewAllMessage.push(GroupMessage);

      console.log(NewAllMessage);
      setTicketType(ticketData.ticketType);
      setloadMessage(NewAllMessage);
      setTheticket(ticketData);
      setpreload(false);
    };

    LoadImportantData();
  }, []);
  const [lang, setlang] = useState();
  const [Theticket, setTheticket] = useState();
  const [preload, setpreload] = useState(true);
  const [NewMessage, setNewMessage] = useState(null);
  const [ErrorAlert, setErrorAlert] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [errMsg, seterrMsg] = useState();
  const [loadMessage, setloadMessage] = useState();
  const [TicketType, setTicketType] = useState();
  const ChangeTicketText = (el) => {
    setNewMessage(el.target.value);
  };
  const GetTicket = async (Ticketid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    var raw = JSON.stringify({});

    const result = await CallApi(
      BaseUrl + GetTicketRequest + "?ticketUuId=" + Ticketid,
      raw,
      myHeaders,
      "GET"
    );

    console.log(result);
    if (result[1] === 200) {
      return result[0].data;
    } else {
      return null;
    }
  };
  const UpdateTheTicket = async () => {
    setloadingstate(true);
    if (NewMessage !== null && NewMessage !== "" && NewMessage != "\n") {
      var timespan = Math.floor(new Date().getTime() / 1000.0);
      let _user = "user";
      /* let Messages = [];
      Messages.push(Theticket.ticketData[0].messages); */
      let newMsg = {};
      newMsg[_user] = NewMessage;
      /*   Messages.push(newMsg); */
      /*   const allMessages = Object.assign({}, ...Messages);
      console.log(allMessages); */
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("UserToken")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        fileUrl: "",
        id: Theticket.id,
        messages: newMsg,
        ticketSenderEnum: "CUSTOMER",
        ticketState: "state_one",
      });
      const result = await CallApi(
        BaseUrl + UpadateTicketRequest,
        raw,
        myHeaders,
        "POST"
      );

      if (result[1] === 200) {
        setloadingstate(false);
        let ticketData = await GetTicket(Ticketid);
        let Date = SetDate(ticketData.ticketData[0].date);
        let NewAllMessage = [];
        var Messages = [];
        let GroupMessage = {};
        /*  console.log(Date); */
        GroupMessage["Date"] = ticketData.ticketData[0].date;
        for (var i = 0; i < ticketData.ticketData.length; i++) {
          let Md = SetDate(ticketData.ticketData[i].date);
          if (Md === Date) {
            Messages.push(ticketData.ticketData[i]);
          } else {
            GroupMessage["AllMessage"] = Messages;
            NewAllMessage.push(GroupMessage);
            Messages = [];
            GroupMessage = {};
            Messages.push(ticketData.ticketData[i]);
            Date = SetDate(ticketData.ticketData[i].date);
            GroupMessage["Date"] = ticketData.ticketData[i].date;
          }
        }

        GroupMessage["AllMessage"] = Messages;
        NewAllMessage.push(GroupMessage);
        setTheticket(ticketData);
        setloadMessage(NewAllMessage);
        setloadingstate(false);
        setNewMessage(null);
      } else if (result[1] === 403) {
        window.location = "/panel/login";
      } else {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(error);
      }
    } else {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(EmptyTicketMessage);
    }
  };
  const UserSender = (sender) => {
    if (sender.includes("CUSTOMER")) {
      return lang === "en" ? "left-message" : "left-message left-message-fr";
    } else {
      return lang === "en" ? "right-message" : "right-message right-message-fr";
    }
  };
  const ScrollBottom = () => {
    let element = document.getElementById("chat-box");
    element.scrollTop = element.scrollHeight;
  };
  const ConvertTimespanToTIme = (timestamp) => {
    var date = new Date(timestamp * 1000),
      hours = date.getHours(),
      minutes = date.getMinutes();
    let time = null;
    let output = null;
    let options = { year: "numeric", month: "long", day: "numeric" };
    var data2 = null;
    if (lang === "fa") {
      data2 = new Date(timestamp * 1000).toLocaleDateString("fa-IR", options);
      time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes);
      output = e2p(time);
    } else {
      data2 = new Date(timestamp * 1000).toLocaleDateString("en-US", options);
      time =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes);
      output = time;
    }
    /*  if (MessageDate != data2) {
      setExistMessageDate(false);
      setMessageDate(data2);
    } else {
      setExistMessageDate(true);
    } */
    return output;
  };
  const SetDate = (timestamp) => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    var data2 = null;
    if (lang === "fa") {
      data2 = new Date(timestamp * 1000).toLocaleDateString("fa-IR", options);
    } else {
      data2 = new Date(timestamp * 1000).toLocaleDateString("en-US", options);
    }
    return data2;
  };
  const SendMessage = async (el) => {
    if (el.keyCode === 13) {
      console.log(NewMessage);
      if (NewMessage != "" && NewMessage != null && NewMessage !== "\n") {
        await UpdateTheTicket();
        el.target.value = "";
      } else {
        el.target.value = "";
        return null;
      }
    }
  };
  const e2p = (s) => s.replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
  const { t } = useTranslation();
  return (
    <>
      {preload === true ? (
        <Loading />
      ) : (
        <>
          <Header title={TicketMenuItemText} />
          <LeftNav />
          <div className="dashboard-panel box-panel ticket-box-panel">
            <div>
              <div>
                <div className=" col-10 mt-3 auto chat-page">
                  <ScrollToBottom className="chat-box mb-3">
                    {ScrollBottom}
                    {loadMessage.map((TheGroup) => (
                      <>
                        <h6>{SetDate(TheGroup.Date)}</h6>
                        {TheGroup.AllMessage.map((message) => (
                          <>
                            {Object.values(message.messages)[0].length > 0 ? (
                              <div
                                className={UserSender(message.ticketSenderEnum)}
                              >
                                <h4>{Object.values(message.messages)[0]}</h4>
                                <p>
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                  ></i>
                                  {ConvertTimespanToTIme(message.date)}
                                </p>
                              </div>
                            ) : null}
                            {message.fileUrl != null &&
                            message.fileUrl != "" ? (
                              <a
                                href={message.fileUrl}
                                target="_blank"
                                className={
                                  message.ticketSenderEnum === "CUSTOMER"
                                    ? "file-sent"
                                    : "file-sent file-sent-admin"
                                }
                              >
                                <p>
                                  {message.fileUrl.split("/")[6]}{" "}
                                  <i
                                    className="fa fa-file"
                                    aria-hidden="true"
                                  ></i>
                                </p>
                              </a>
                            ) : null}
                          </>
                        ))}
                      </>
                    ))}
                  </ScrollToBottom>
                  <TextArea
                    lable="Message"
                    Id="Replay"
                    placeholder={t(PlaceholderMessage)}
                    onChange={(e) => ChangeTicketText(e)}
                    onKeyDown={(e) => SendMessage(e)}
                  />
                  <div className="pl-5 redirect-btn mb-0">
                    <a
                      href={
                        TicketType === "special-game"
                          ? "/panel/order-spcialGame"
                          : "/panel/ticket"
                      }
                      className="reverce-primary-btn mt-0 m-1 auto"
                    >
                      {t(TicketsListTextBtn)}
                    </a>
                    <a
                      onClick={UpdateTheTicket}
                      className="primary-btn mt-0 m-1 auto"
                    >
                      {t(verificationBtnText)}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {ErrorAlert === true && loadingstate === false ? (
        <ErrorPopUp ErrorText={errMsg} />
      ) : null}
      {loadingstate === true ? <Loading /> : null}
    </>
  );
};

export default UpdateTicket;
