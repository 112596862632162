import React, { useEffect } from "react";
import { BaseUrl, ProfilrInfo } from "./Texts/ApiUrl";
import { CallApi } from "./PanelComponent/CallApi/CallApi";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { ChangeLang } from "./Redux/lang/LangAction";
const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    // bar
    var lang = params.get("lang");
    console.log(lang);
    if (lang !== null) {
      i18next.changeLanguage(lang);
      dispatch(ChangeLang(lang));
    }
    GetProfile();
  }, []);

  const GetProfile = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
    } else {
      /*  window.location = "/panel/login"; */
    }
  };
  /* const { t } = useTranslation(); */
  return <></>;
};

export default Home;
