import React from "react";
import { useTranslation } from "react-i18next";
import "./CheckBox.css";
const CheckBox = (props) => {
  const { t } = useTranslation();
  return (
    <div className="boxes">
      <input
        type="checkbox"
        id="fruit1"
        name="fruit-1"
        value="Apple"
        onChange={props.onChange}
        checked={props.chacked}
      />
      <label for="fruit1">{t(props.Lable)}</label>
    </div>
  );
};

export default CheckBox;
