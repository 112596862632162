import React, { useState } from "react";
import Field from "../../../Tools/Inputs/input";
import {
  PlayableUrlInputLable,
  PlayableNameInputLable,
  ErrorAddAsset,
  DataInvalid,
  error,
  PlayableFilesInputLable,
  LargesImageInAddAsset,
  PlayableCategoryInputLable,
  PlayableIdInputLable,
  AddPlayableSubmitText,
} from "../../../Texts/Text";
import FileUpload from "../../../Tools/FileUpload/FileUpload";
import { CallApi } from "../../CallApi/CallApi";
import ErrorPopUp from "../../../ErrorPopUp/ErrorPopUp";
import Loading from "../../../Tools/Loading/Loading";
import { AddPlayableUrl, BaseUrl, AddAssetUrl } from "../../../Texts/ApiUrl";

const AddPlayable = () => {
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);

  const [Url, setUrl] = useState();
  const [category, setcategory] = useState();
  const [Name, setName] = useState();

  const [Files, setFiles] = useState();

  const Changeurl = (el) => {
    setUrl(el.target.value);
  };
  const UploadFile = (el) => {
    setFiles(el.target.files);
  };
  const ChangeCategory = (el) => {
    setcategory(el.target.value);
  };
  const ChangeName = (el) => {
    setName(el.target.value);
  };
  const AddAsset = async () => {
    var Assets = [];

    for (var i = 0; i < Files.length; i++) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("UserToken"));

      var formdata = new FormData();
      formdata.append("file", Files[i]);
      formdata.append("data", '{"name":"arsalan"}');

      const result = await CallApi(
        BaseUrl + AddAssetUrl,
        formdata,
        myHeaders,
        "POST"
      );

      if (result[1] === 200) {
        setErrorAlert(false);
        //setloadingstate(false);
        result[0].data.assets.fileUrl =
          result[0].data.baseAssetPath + result[0].data.assets.fileUrl;
        Assets.push(result[0].data.assets);
      } else if (result[1] === 403) {
        setErrorAlert(false);
        setloadingstate(false);
        window.location = "/login";
      } else if (result[1] === 413) {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(LargesImageInAddAsset);
      } else {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(ErrorAddAsset);
      }
    }
    //console.log(Assets);
    console.log(Assets);

    return Assets;
  };
  const Add = async () => {
    var PlayableAssets = [];
    PlayableAssets = await AddAsset();

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      assets: PlayableAssets,
      categoryName: category,
      name: Name,
      url: Url,
    });

    const result = await CallApi(
      BaseUrl + AddPlayableUrl,
      raw,
      myHeaders,
      "POST"
    );

    if (result[1] === 200) {
      setErrorAlert(false);
      setloadingstate(false);
      window.location = "/panel/Dashboard";
    } else if (result[1] === 500) {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(DataInvalid);
    } else {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(error);
    }
  };

  return (
    <div className="col-lg-6 col-md-8 col-12 mt-5 p-0 form auto">
      <div className="form-body pt-5 pb-5">
        <form>
          <FileUpload
            lable={PlayableFilesInputLable}
            Change={(e) => UploadFile(e)}
          />
          <Field
            Id="PlayableCategory"
            type="text"
            lable={PlayableCategoryInputLable}
            value={""}
            onChange={(e) => ChangeCategory(e)}
          />
          <Field
            Id="PlayableName"
            type="text"
            lable={PlayableNameInputLable}
            value={""}
            onChange={(e) => ChangeName(e)}
          />
          <Field
            Id="PlayableUrl"
            aria="emailHelp"
            type="text"
            lable={PlayableUrlInputLable}
            value={""}
            onChange={(e) => Changeurl(e)}
          />
          <a onClick={Add} className="btn btn-primary mt-3 auto">
            {AddPlayableSubmitText}
          </a>
          {ErrorAlert === true && loadingstate === false ? (
            <ErrorPopUp ErrorText={errMsg} />
          ) : null}
          {loadingstate === true ? <Loading /> : null}
        </form>
      </div>
    </div>
  );
};

export default AddPlayable;
