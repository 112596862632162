import React, { Component } from "react";
import { useTranslation } from "react-i18next";
const SuccessAlert = (props) => {
  const { t } = useTranslation();
  return (
    <div className="success-alert">
      <span></span>
      <h6> {t(props.Text)}</h6>
    </div>
  );
};

export default SuccessAlert;
