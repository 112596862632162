import React, { useState } from "react";
import { FullScreenbtnText } from "../../../Texts/Text";
import FullScreenGame from "../FullScreen/FullScreenGame";
import "./AssetImg.css";
import { useTranslation } from "react-i18next";
const AssetImg = (props) => {
  const [visibleFullScreem, setvisibleFullScreem] = useState(false);

  const LoadFullScreen = async () => {
    setvisibleFullScreem(true);
  };
  const CloseFullScreen = async () => {
    setvisibleFullScreem(false);
  };
  const ScrollDown = async () => {
    let element = document.getElementById("EditPlayableAssets");
    element.scrollTop = element.scrollHeight;
  };
  const { t } = useTranslation();
  return (
    <>
      {console.log(props.LandScape)}
      {props.LandScape === true ? (
        <div className="auto pt-3 pb-0 asset-img lanscape-playable">
          <iframe src={props.src + "/"} allowfullscreen></iframe>
          {props.EditScroll === true ? (
            <div className="flex-btn">
              <a className="fullscreen-btn" onClick={(e) => ScrollDown()}>
                <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                {props.EditSctollText}
              </a>
              <a className="fullscreen-btn" onClick={(e) => LoadFullScreen()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-fullscreen"
                  viewBox="0 0 16 16"
                >
                  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
                </svg>{" "}
                {t(FullScreenbtnText)}
              </a>
            </div>
          ) : (
            <a className="fullscreen-btn" onClick={(e) => LoadFullScreen()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-fullscreen"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z" />
              </svg>{" "}
              {t(FullScreenbtnText)}
            </a>
          )}
        </div>
      ) : (
        <div className="col-lg-3 col-md-6 pt-3 p-3 asset-img">
          <iframe src={props.src + "/"} allowfullscreen></iframe>
          <a className="fullscreen-btn" onClick={(e) => LoadFullScreen()}>
            {t(FullScreenbtnText)}
          </a>
        </div>
      )}
      {visibleFullScreem === true ? (
        <FullScreenGame
          close={
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={(e) => CloseFullScreen()}
            ></i>
          }
          visible={visibleFullScreem}
          child={<iframe src={props.src + "/"} allowfullscreen></iframe>}
        />
      ) : null}
    </>
  );
};

export default AssetImg;
