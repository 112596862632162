import React, { useState, useEffect } from "react";
import {
  BaseUrl,
  ProfilrInfo,
  ChangePassApi,
  ChangePhoneNumberRequest,
  ChangeUserDate,
} from "../../../../Texts/ApiUrl";
import { CallApi } from "../../../CallApi/CallApi";
import Field from "../../../../Tools/Inputs/input";
import ErrorPopUp from "../../../../ErrorPopUp/ErrorPopUp";
import Loading from "../../../../Tools/Loading/Loading";
import SuccessAlert from "../../../../ErrorPopUp/SuccessAlert";
import UserAvatar from "../../../../Images/user-avatar.png";
import {
  NewPassWordLableText,
  AddPlayableSubmitText,
  OldPassWordLableText,
  EmailLable,
  SuccessText,
  error,
  InvalidPasswordErrorText,
  EmailNotFoundText,
  SamePassword,
  ChnagePassInProfileTitle,
  ChangePhoneNumberInProfileTitle,
  PhoneNumberFieldLable,
  ChangePhoneFaild,
  CancelText,
  InvalidFormatPhoneText,
  UserNameText,
  PasswordLable,
  WebSiteAddressFieldLable,
  ForgetPassText,
  SubjectRegisterOptionLable,
  priorityDropDownLableText,
} from "../../../../Texts/Text";
import { useTranslation } from "react-i18next";
import { Alert } from "bootstrap";
import { Link } from "react-router-dom";
import ImageUpload from "../ImageUploder/ImageUpload";
const ChangePasswordForm = () => {
  const [success, setsuccess] = useState(false);
  const [successChange, setsuccessChange] = useState(false);
  const [TheUser, seTheUser] = useState();
  const [preload, setpreload] = useState(true);
  /*  const [Email, setEmail] = useState(true); */
  const [OldPass, setOldPass] = useState(true);
  const [NewPass, setNewPass] = useState(true);
  const [ChangePass, setChangePass] = useState(false);
  const [ChangeMobile, setChangeMobile] = useState(false);
  const [PhoneNum, setPhoneNum] = useState(null);
  const [UserName, setUserName] = useState(null);
  const [WebUrl, setWebUrl] = useState(null);
  const [ErrorAlert, setErrorAlert] = useState(false);
  const [errMsg, seterrMsg] = useState();
  const [subject, setsubject] = useState("");
  const [ValidationPhone, setValidationPhone] = useState(true);
  const [loadingstate, setloadingstate] = useState(false);
  useEffect(() => {
    /* console.log(localStorage.getItem("UserToken")); */

    const getData = async () => {
      let userdata = await GetUser();
      seTheUser(userdata);
      setUserName(userdata.fullName);
      userdata.webSite === "string"
        ? setWebUrl("")
        : setWebUrl(userdata.webSite);

      setPhoneNum(userdata.mobile);

      setpreload(false);
      let el = document.getElementById("SubjectRegister");
      el.value = userdata.jobTitle;
    };
    getData();
  }, []);
  const GetUser = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));
    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };
  /*  const ReadEmail = (el) => {
    setEmail(el.target.value);
  }; */
  const ReadOldPass = (el) => {
    setOldPass(el.target.value);
    setChangePass(true);
  };
  const ReadNewPass = (el) => {
    setNewPass(el.target.value);
    setChangePass(true);
  };
  const ChangeUserName = (el) => {
    setUserName(el.target.value);
    setChangeMobile(true);
  };
  const ChangeWebUrl = (el) => {
    setWebUrl(el.target.value);
    setChangeMobile(true);
  };
  const ChangePassword = async () => {
    setloadingstate(true);
    setsuccess(false);
    if (NewPass !== OldPass) {
      var Url = BaseUrl + ChangePassApi;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: TheUser.email,
        newPassword: NewPass,
        password: OldPass,
      });
      const result = await CallApi(Url, raw, myHeaders, "POST");
      if (result[1] === 200) {
        setsuccessChange(true);
        setloadingstate(false);
        setErrorAlert(false);
        setsuccess(true);
        seterrMsg(SuccessText);
      } else if (result[1] === 403) {
        setErrorAlert(true);
        setloadingstate(false);
        window.location = "/panel/login";
      } else if (result[1] === 404) {
        setErrorAlert(true);
        setloadingstate(false);
        seterrMsg(EmailNotFoundText);
      } else if (result[1] === 406) {
        setErrorAlert(true);
        setloadingstate(false);
        seterrMsg(InvalidPasswordErrorText);
      } else {
        setErrorAlert(true);
        setloadingstate(false);
        seterrMsg(error);
      }
    } else {
      setErrorAlert(true);
      setloadingstate(false);
      seterrMsg(SamePassword);
    }
  };
  const ChangePhoneNumber = async (el) => {
    setPhoneNum(el.target.value);
    setChangeMobile(true);
    /*  const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i; */
    var regex = /^[0-9\b]+$/;
    if (!regex.test(el.target.value)) {
      setValidationPhone(false);
    } else if (el.target.value.length != 11) {
      setValidationPhone(false);
    } else {
      setValidationPhone(true);
    }
  };
  const ChangePhoneNum = async () => {
    setsuccess(false);
    if (ValidationPhone === true) {
      if (PhoneNum != null || PhoneNum != "") {
        let elements = document.getElementById("FileUpload");

        var Url = BaseUrl + ChangeUserDate;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.getItem("UserToken"));

        var raw = JSON.stringify({
          mobile: PhoneNum,
          fullName: UserName,
          jobTitle: subject,
          webSite: WebUrl,
        });
        console.log(raw);
        var formdata = new FormData();
        if (elements.files.length > 0) {
          formdata.append("file", elements.files[0]);
        }

        formdata.append("data", raw);

        const result = await CallApi(Url, formdata, myHeaders, "POST");
        console.log(result);
        if (result[1] === 200) {
          setloadingstate(false);
          setErrorAlert(false);
          setsuccess(true);
          seterrMsg(SuccessText);
          seTheUser(result[0].data);
          setsuccessChange(true);
        } else {
          setErrorAlert(true);
          setloadingstate(false);
          seterrMsg(ChangePhoneFaild);
        }
      }
    } else {
      setErrorAlert(true);
      setloadingstate(false);
      seterrMsg(InvalidFormatPhoneText);
    }
  };
  const Change = async () => {
    if (ChangeMobile === true) {
      await ChangePhoneNum();
      setChangeMobile(false);
    }
  };
  const TypeNumber = (e) => {
    if (!e) {
      e = window.event;
    }

    var code = e.keyCode || e.which;

    if (!e.ctrlKey) {
      if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
        return false;
      }
    }
    return true;
  };
  const UploadFile = (el, ImageId) => {
    setChangeMobile(true);
    let data = URL.createObjectURL(el.target.files[0]);
    //console.log(el);
    var elImage = document.getElementById(ImageId);
    elImage.src = data;
  };
  const ChangeSubject = async (el) => {
    setsubject(el.target.options[el.target.selectedIndex].text);
    setChangeMobile(true);
  };
  const { t } = useTranslation();
  return (
    <>
      {preload === true ? (
        <Loading />
      ) : (
        <div>
          <div className="col-lg-8 col-md-10 col-11 p-0 auto">
            <div className="profile-title auto mt-3">
              <ImageUpload
                ImageId="UserAvatar"
                src={
                  TheUser.userProfileImage != "" ||
                  TheUser.userProfileImage != null
                    ? TheUser.userProfileImage
                    : { UserAvatar }
                }
                onChange={(e) => UploadFile(e, "UserAvatar")}
              />
              <h2>{TheUser.fullName}</h2>
              <p>{TheUser.email}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 col-12 p-0 auto">
            <div className="form-body pt-3 pb-5 site-direction">
              <form>
                <div className="form-group">
                  <label for="SubjectRegister">
                    {t(SubjectRegisterOptionLable)}
                  </label>
                  <select
                    className="custom-select my-1"
                    id="SubjectRegister"
                    onChange={(e) => ChangeSubject(e)}
                  >
                    <option selected="selected" value={t("MoreOption")}>
                      {t("MoreOption")}
                    </option>
                    <option value={t("Marketing")}>{t("Marketing")}</option>
                    <option value={t("GameStudio")}>{t("GameStudio")}</option>
                  </select>
                </div>
                <Field
                  Id="UserName"
                  type="text"
                  value={UserName}
                  lable={UserNameText}
                  placeholder="UserNamePlaceholder"
                  onChange={(e) => ChangeUserName(e)}
                />
                <Field
                  Id="Phone"
                  maxlength="11"
                  type="text"
                  value={PhoneNum}
                  placeholder="MobilePlaceholder"
                  lable={PhoneNumberFieldLable}
                  onChange={(e) => ChangePhoneNumber(e)}
                  onKeyDown={(e) => TypeNumber(e)}
                />
                {/* <Field
                  Id="Password"
                  type="password"
                  placeholder="wackotech12345"
                  lable={PasswordLable}
                  onChange={(e) => ChangePhoneNumber(e)}
                /> */}
                <Field
                  Id="WebUrl"
                  placeholder="WebUrlPlaceholder"
                  type="text"
                  value={WebUrl}
                  lable={WebSiteAddressFieldLable}
                  onChange={(e) => ChangeWebUrl(e)}
                />
                {/*  <Link to="/panel/forget-password" className="fotget-pass-text">
                  {t(ForgetPassText)}
                </Link> */}
                <div className="redirect-btn mt-5">
                  <a
                    href="/panel/Dashboard"
                    className="reverce-primary-btn auto"
                  >
                    {t(CancelText)}
                  </a>
                  <a onClick={Change} className="primary-btn auto">
                    {t(AddPlayableSubmitText)}
                  </a>
                </div>

                {ErrorAlert === true && loadingstate === false ? (
                  <ErrorPopUp ErrorText={errMsg} />
                ) : null}
                {loadingstate === true ? <Loading /> : null}
                {success === true ? <SuccessAlert Text={errMsg} /> : null}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangePasswordForm;
