export const priorityoneText = "VeryLow";
export const priorityTwoText = "Low";
export const priorityThreeText = "Middle";
export const priorityFourText = "Hight";
export const priorityFiveText = "VeryHight";

//Type Of Ticket

export const TypeOneValue = "technical";
export const TypeTwoValue = "special-game";

export const TypeOneText = "FanniText";
export const TypeTwotext = "SpecialGameText";
