import React from "react";
import { useTranslation } from "react-i18next";
import { FaildPayText, GoToDashboardTextBtn } from "../../../Texts/Text";
import Loading from "../../../Tools/Loading/Loading3.gif";

const FaildPay = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-6 col-md-10 col-12 p-3 status-pay auto">
        <div>
          <img src={Loading} />
          <p>
            <img src="https://img.icons8.com/emoji/48/000000/warning-emoji.png" />
            {t(FaildPayText)}
          </p>
          <a href="/panel/dashboard" className="green-btn">
            {t(GoToDashboardTextBtn)}
          </a>
        </div>
      </div>
    </>
  );
};

export default FaildPay;
