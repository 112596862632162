import React, { Component } from "react";
import TimeLine from "../TimeLine/TimeLine";
import LeftNav from "../../LeftNav/LeftNav";
import Section from "../../PanelSection/Section";
import Footer from "../DesigneAppFooter/DesignAppFooter";
import UploadMusic from "../UploadMusic/UploadMusic";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import { DesignAppMenuItemText } from "../../../Texts/Text";

import {
  ChooseMusicFooterButtonText,
  ChooseMusicFooterDescription,
  ChooseMusicFooterQuestionText,
} from "../../../Texts/Text";

const ChooseMusic = (props) => {
  return (
    <>
      <Header title={DesignAppMenuItemText} />
      <LeftNav />
      <div className="dashboard-panel box-panel">
        <div>
          <div id="EditPlayableAssets">
            <TimeLine PageNum="3" />
            <Section
              styles="ps-0 mt-5 site-direction"
              ChildElement={
                <UploadMusic NextStep={props.child} LastPage={props.child2} />
              }
            />
          </div>
        </div>
        {/*   <p className="pt-3"></p> */}
      </div>
    </>
  );
};

export default ChooseMusic;
