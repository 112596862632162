import React, { useState, useEffect } from "react";
import Loading from "../../../Tools/Loading/Loading3.gif";
import { WaitingText } from "../../../Texts/Text";
import { useTranslation } from "react-i18next";

const SuccessPay = (props) => {
  const [number, setnumber] = useState(5);
  const UpdateTimer = () => {
    if (number > 0) {
      setTimeout(() => {
        setnumber(number - 1);
      }, 1200);
      for (let i = 1; i <= 5; i++) {
        if (i != number) {
          let el = document.getElementById(i);
          el.innerHTML = "";
          el.classList.remove("active-span");
        } else {
          let el = document.getElementById(i);
          el.innerHTML = number;
          el.className = "active-span";
        }
      }
    } else {
      window.location =
        "/panel/designapp/customize-playable?clientRefId=" + props.refId;
    }
  };
  useEffect(() => {
    UpdateTimer();
  }, [number]);
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-6 col-md-10 col-12 p-3 status-pay auto">
        <div>
          <img src={Loading} />
          <p>{t(WaitingText)}</p>
          <ul>
            <li>
              <span id="1"></span>
            </li>
            <li>
              <span id="2"></span>
            </li>
            <li>
              <span id="3"></span>
            </li>
            <li>
              <span id="4"></span>
            </li>
            <li>
              <span id="5"></span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SuccessPay;
