import React, { useState } from "react";
import Field from "../../Tools/Inputs/input";
import {
  EmailPlaceholderText,
  verificationBtnText,
  error,
  EmailNotFoundText,
} from "../../Texts/Text";
import ErrorPopUp from "../../ErrorPopUp/ErrorPopUp";
import Loading from "../../Tools/Loading/Loading";
import { BaseUrl, SendTokenforgetPass } from "../../Texts/ApiUrl";
import { CallApi } from "../../PanelComponent/CallApi/CallApi";
import { useTranslation } from "react-i18next";
const SendForgetPasswordToken = () => {
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [Email, setEmail] = useState();
  const ChangeEmail = (el) => {
    setEmail(el.target.value);
  };
  const SendForgetPassToken = async () => {
    setloadingstate(true);
    var Url = BaseUrl + SendTokenforgetPass + "?email=" + Email;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    console.log(result);
    if (result[1] === 200) {
      setErrorAlert(false);
      setloadingstate(false);
      localStorage.setItem("EmailReceiver", Email);
      window.location = "/panel/change-password";
    } else if (result[1] === 403) {
      setloadingstate(false);
      seterrMsg(EmailNotFoundText);
      setErrorAlert(true);
    } else {
      setloadingstate(false);
      seterrMsg(error);
      setErrorAlert(true);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="col-lg-6 col-md-8 col-12 mt-5 p-0 form auto">
      <div className="form-body pt-5 pb-5">
        <form>
          <Field
            Id="Email"
            aria="emailHelp"
            type="text"
            lable={EmailPlaceholderText}
            onChange={(e) => ChangeEmail(e)}
          />
          <a onClick={SendForgetPassToken} className="primary-btn mt-3 auto">
            {t(verificationBtnText)}
          </a>
          {ErrorAlert === true && loadingstate === false ? (
            <ErrorPopUp ErrorText={errMsg} />
          ) : null}
          {loadingstate === true ? <Loading /> : null}
        </form>
      </div>
    </div>
  );
};

export default SendForgetPasswordToken;
