import React, { useEffect, useState, useContext } from "react";
import "../EditAsset/EditAsset.css";
import {
  EditAssetTitle,
  EditTextTitle,
  EditAssetText,
  LimitSize,
  LargesImageInAddAsset,
  ErrorAddAsset,
  RedirectPayPageText,
  RedirectChooseMusciText,
} from "../../../Texts/Text";
import AssetImg from "../AssetImg/AssetImg";
import { CallApi } from "../../CallApi/CallApi";
import UplaodAsset from "../EditAsset/UploadAsset/UploadAsset";
import ErrorPopUp from "../../../ErrorPopUp/ErrorPopUp";
import Loading from "../../../Tools/Loading/Loading";
import { BaseUrl, StepTwoApi } from "../../../Texts/ApiUrl";
import {
  PlayableData,
  CreateFolderName,
} from "../DesignAppStepsComponents/DesignApp";
import { useTranslation } from "react-i18next";

const EditAsset = (props) => {
  const ThePlayable = useContext(PlayableData);
  const MainUrl = useContext(CreateFolderName);
  const [playableasset, setplayableasset] = useState(null);
  const [playabledesc, setplayabledesc] = useState(null);
  const [LoadPlayable, setLoadPlayable] = useState(true);
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [GamePreviewLink, setGamePreviewLink] = useState();
  const [refteshIframe, setrefteshIframe] = useState();
  useEffect(() => {
    console.log(localStorage.getItem("UserToken"));
    console.log(MainUrl);
    const LoadData = async () => {
      setrefteshIframe(1);
      if (MainUrl === null) {
        setGamePreviewLink(ThePlayable.url);
      } else {
        setGamePreviewLink(
          "https://assets.wackotech.com/purchased_playable/" +
            MainUrl.mainFolder
        );
      }
      let data = await Object.values(ThePlayable.assets);
      let Wh = await DescSize(ThePlayable.desc);
      console.log(Wh);
      setplayabledesc(Wh);
      //console.log(data);
      setplayableasset(data);
      setLoadPlayable(false);
    };
    LoadData();
  }, []);
  const ReplaceAsset = async (FileName, File) => {
    console.log(FileName);
    setloadingstate(true);
    await setGamePreviewLink("");
    let data =
      '{"name":"' +
      FileName +
      '","folderPath":"' +
      "/" +
      MainUrl.mainFolder +
      MainUrl.assetFolder +
      '"}';
    console.log(data);
    var myHeaders = new Headers();

    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );

    var formdata = new FormData();
    formdata.append("data", data);
    formdata.append("file", File);

    const result = await CallApi(
      BaseUrl + StepTwoApi,
      formdata,
      myHeaders,
      "POST"
    );
    //console.log(formdata);
    if (result[1] === 200) {
      //await setGamePreviewLink("");
      await setGamePreviewLink(result[0].data);
      setloadingstate(false);
      //setrefteshIframe(refteshIframe + 1);
      //console.log(result[0].data);
    }
  };
  const ChangeImage = async (el, playableid) => {
    let limitSize = el.target.files[0].size / 1024;
    if (limitSize < 2048) {
      await ReplaceAsset(playableid, el.target.files[0]);
      let data = await URL.createObjectURL(el.target.files[0]);
      //console.log(el);
      var elImage = document.getElementById(playableid + "img");
      elImage.src = data;
    } else {
      setErrorAlert(true);
      seterrMsg(LimitSize);
    }
  };
  const DescSize = async (desc) => {
    var result = Object.keys(desc).map((key) => [key, desc[key]]);

    for (let i = 0; i < result.length; i++) {
      let w = result[i][1].split(",")[1].split(":")[1].replace("px", "");
      let h = result[i][1].split(",")[2].split(":")[1].replace("px", "");
      //let size = result[i][1].split(",")[0].split(":")[1];
      result[i][1] = w + "*" + h;
    }

    return Object.fromEntries(result);
  };
  //public method
  const { t } = useTranslation();
  return (
    <>
      <>
        {refteshIframe > 0 && ThePlayable.landScape === true ? (
          <AssetImg
            LandScape={ThePlayable.landScape}
            src={GamePreviewLink}
            EditScroll={true}
            EditSctollText={t("Edit_Asset")}
          />
        ) : null}
        <div
          className={
            ThePlayable.landScape === true
              ? "col-12 customize-asset p-1 mb-5"
              : "col-lg-9 col-md-6 customize-asset py-0 pl-3 pr-3 pt-2"
          }
        >
          {/* <h1 className="mt-3">{t(EditAssetTitle)}</h1> */}
          <div className="edit-asset-item">
            {LoadPlayable === true
              ? null
              : playableasset.map((asset) => (
                  <>
                    <div>
                      <img
                        id={asset.name.split(".")[0] + "img"}
                        src={asset.fileUrl}
                        alt={asset.name}
                      />
                      <div>
                        <UplaodAsset
                          id={asset.name.split(".")[0]}
                          dataName={asset.name.split(".")[0]}
                          Change={(e) =>
                            ChangeImage(e, asset.name.split(".")[0])
                          }
                          Text={t(EditAssetText)}
                          accept="image/png, image/jpeg, image/gif"
                          fonticon={
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                          }
                        />
                      </div>
                      <p>{playabledesc[asset.name.split(".")[0]]}</p>
                      <h6>{asset.name.split(".")[0]}</h6>
                    </div>
                  </>
                ))}
          </div>
        </div>
        {refteshIframe > 0 && ThePlayable.landScape === false ? (
          <AssetImg LandScape={ThePlayable.landScape} src={GamePreviewLink} />
        ) : null}

        {ErrorAlert === true && loadingstate === false ? (
          <ErrorPopUp ErrorText={errMsg} />
        ) : null}
        {loadingstate === true ? <Loading /> : null}
      </>
      {ThePlayable.landScape === false ? (
        <div className="col-12 fixed-nav-bottom pt-2">
          <div className="redirect-btn mb-0 ">
            {props.LastPage}
            {props.NextStep}
          </div>
        </div>
      ) : (
        <div className="col-12 pt-2">
          <div className="redirect-btn mb-0 ">
            {props.LastPage}
            {props.NextStep}
          </div>
        </div>
      )}
    </>
  );
};

export default EditAsset;
