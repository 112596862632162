import "./App.css";
import React, { useState, useEffect } from "react";
import Home from "./Home";
import { Route } from "react-router-dom";
import Dashboard from "./PanelComponent/Dashboard/Dashboard";
import Search from "./PanelComponent/Search/Search";
import ChooseTemplate from "./PanelComponent/DesignApp/DesignAppStepsComponents/ChooseTemplate";
import DesigneApp from "./PanelComponent/DesignApp/DesignAppStepsComponents/DesignApp";
import ChooseMusic from "./PanelComponent/DesignApp/DesignAppStepsComponents/ChooseMusic";
import LoginForm from "./LoginFormComponent/FormActoin/Login";
import RegisterForm from "./LoginFormComponent/FormActoin/Register";
import VerifyForm from "./LoginFormComponent/Verify/Veryfy";
import AddPlayable from "./PanelComponent/SuperAdmin/AddPlayable/AddPlayable";
import ForgetPass from "./LoginFormComponent/ForgetPassword/SendForgetPasswordToken";
import SetNewPass from "./LoginFormComponent/ForgetPassword/SetNewPassword";
import ChangePassword from "./PanelComponent/ProfileComponents/AccountSetting/ChangePassword/ChangePassword";
import AddTicket from "./PanelComponent/Ticket/AddTicket/AddTicket";
import Tickets from "./PanelComponent/Ticket/AllTickets/Tickets";
import UpdateTicket from "./PanelComponent/Ticket/UpdateTicket/UpdateTicket";
import GameDetail from "./PanelComponent/ProfileComponents/GameDetail/GameDetail";
import Redirection from "./PanelComponent/DesignApp/PayStatus/Redirection";
import Cookies from "js-cookie";
import { TypeOneValue, TypeTwoValue } from "./Texts/Options";
import { Provider, useDispatch } from "react-redux";
import store from "./Redux/Store";
import { ChangeLang } from "./Redux/lang/LangAction";
import { useSelector } from "react-redux";
import i18next from "i18next";

const App = () => {
  const [Cookie, setCookie] = useState();
  /* const dispatch = useDispatch(); */
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

  const dispatch = useDispatch();
  useEffect(() => {
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    // bar
    var langp = params.get("lang");

    /* console.log(lang); */
    if (langp !== null && (langp === "fa" || langp === "en")) {
      i18next.changeLanguage(langp);
    }

    const theCookie = Cookies.get("i18next");
    dispatch(ChangeLang(theCookie));
    if (theCookie === "en") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "ltr");
      r.style.setProperty("--align", "left");
      r.style.setProperty("--float-right", "left");
      r.style.setProperty("--float-left", "right");
      r.style.setProperty("--reverce-direction", "rtl");
      r.style.setProperty("--font", "Roboto,IRANSansWeb");
    }
    if (theCookie === "fa") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "rtl");
      r.style.setProperty("--align", "right");
      r.style.setProperty("--float-right", "right");
      r.style.setProperty("--float-left", "left");
      r.style.setProperty("--reverce-direction", "ltr");
      r.style.setProperty("--font", "IRANSansWeb");
    }
  }, []);
  return (
    <div className="App">
      {/*   <Route exact path="/" component={() => <Landing />} /> */}
      <Route exact path="/panel" component={() => <Dashboard />} />
      <Route exact path="/panel/dashboard" component={() => <Dashboard />} />
      <Route
        exact
        path="/panel/designapp/choosetemplate"
        component={() => <ChooseTemplate />}
      />
      <Route
        exact
        path="/panel/designApp"
        component={() => <ChooseTemplate />}
      />
      <Route
        exact
        path="/panel/designApp/redirection"
        component={() => <Redirection />}
      />
      <Route
        exact
        path="/panel/designApp/customize-playable"
        component={() => <DesigneApp />}
      />
      <Route
        exact
        path="/panel/designapp/choosemusic"
        component={() => <ChooseMusic />}
      />
      <Route exact path="/panel/Search" component={() => <Search />} />

      <Route exact path="/panel/login" component={() => <LoginForm />} />
      <Route exact path="/panel/register" component={() => <RegisterForm />} />
      <Route exact path="/panel/verify" component={() => <VerifyForm />} />
      <Route
        exact
        path="/panel/add-playable"
        component={() => <AddPlayable />}
      />
      <Route
        exact
        path="/panel/forget-password"
        component={() => <ForgetPass />}
      />
      <Route
        exact
        path="/panel/change-password"
        component={() => <SetNewPass />}
      />
      <Route
        exact
        path="/panel/account-setting"
        component={() => <ChangePassword />}
      />
      <Route
        exact
        path="/panel/ticket/add"
        component={() => <AddTicket Type={TypeOneValue} />}
      />
      <Route
        exact
        path="/panel/special-game-ticket/add"
        component={() => <AddTicket Type={TypeTwoValue} />}
      />
      <Route
        exact
        path="/panel/ticket"
        component={() => <Tickets Type={TypeOneValue} />}
      />
      <Route
        exact
        path="/panel/ticket/edit"
        component={() => <UpdateTicket />}
      />
      <Route
        exact
        path="/panel/order-spcialgame"
        component={() => <Tickets Type={TypeTwoValue} />}
      />
      <Route
        exact
        path="/panel/dashboard/detailgame"
        component={() => <GameDetail />}
      />
    </div>
  );
};

export default App;
