import React, { Component } from "react";
import "./DesignAppFooter.css";
import { CopyRightDesignApp1, CopyRightDesignApp2 } from "../../../Texts/Text";
class Footer extends Component {
  state = {};

  render() {
    return (
      <>
        {/*  <div className="col-12 descriptions-designapp">
          <h3>{this.props.questionText}</h3>
          <p> {this.props.Description}</p>
          <a href={this.props.link}>{this.props.buttonText}</a>
          <h6>
            {CopyRightDesignApp1}{" "}
            <i class="fa fa-copyright" aria-hidden="true"></i>
            {CopyRightDesignApp2}
          </h6>
        </div>{" "} */}
      </>
    );
  }
}

export default Footer;
