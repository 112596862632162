import React, { useState } from "react";
import Field from "../../Tools/Inputs/input";
import {
  EmailPlaceholderText,
  AddPlayableSubmitText,
  error,
  EmailNotFoundText,
  verificationHeadText,
  NewPassWordLableText,
  DontSendTokenText,
  ResendTokenForgetPass,
} from "../../Texts/Text";
import ErrorPopUp from "../../ErrorPopUp/ErrorPopUp";
import Loading from "../../Tools/Loading/Loading";
import { BaseUrl, ForgetPass, ReSendTokenforgetPass } from "../../Texts/ApiUrl";
import { CallApi } from "../../PanelComponent/CallApi/CallApi";
import SuccessAlert from "../../ErrorPopUp/SuccessAlert";
import { useTranslation } from "react-i18next";
const SetNewPassword = () => {
  const [Success, setSuccess] = useState(false);
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [Email, setEmail] = useState();
  const [Token, setToken] = useState();
  const [NewPass, setNewPass] = useState();
  const ChangeEmail = (el) => {
    setEmail(el.target.value);
  };
  const ChangeToken = (el) => {
    setToken(el.target.value);
  };
  const ChangeNewPass = (el) => {
    setNewPass(el.target.value);
  };
  const ChangePassWord = async () => {
    setloadingstate(true);
    var Url = BaseUrl + ForgetPass;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: Email,
      forgetPasswordToken: Token,
      newPassword: NewPass,
    });
    const result = await CallApi(Url, raw, myHeaders, "POST");
    if (result[1] === 200) {
      setErrorAlert(false);
      setloadingstate(false);
      localStorage.removeItem("EmailReceiver");
      window.location = "/panel/login";
    } else if (result[1] === 403) {
      setloadingstate(false);
      seterrMsg(EmailNotFoundText);
      setErrorAlert(true);
    } else {
      setloadingstate(false);
      seterrMsg(error);
      setErrorAlert(true);
    }
  };
  const ResendToken = async () => {
    setloadingstate(true);
    let Email = localStorage.getItem("EmailReceiver");
    var Url = BaseUrl + ReSendTokenforgetPass + "?email=" + Email;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      setErrorAlert(false);
      setloadingstate(false);

      seterrMsg(ResendTokenForgetPass);
      setInterval(() => {
        setSuccess(false);
      }, 2000);
    } else if (result[1] === 403) {
      setloadingstate(false);
      seterrMsg(EmailNotFoundText);
      setErrorAlert(true);
    } else {
      setloadingstate(false);
      seterrMsg(error);
      setErrorAlert(true);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="col-lg-6 col-md-8 col-12 mt-5 p-0 form auto changepass-form">
      <div className="form-body pt-5 pb-5">
        <form>
          <Field
            Id="Email"
            aria="emailHelp"
            type="text"
            lable={EmailPlaceholderText}
            onChange={(e) => ChangeEmail(e)}
          />
          <Field
            Id="ForgetPassToken"
            type="text"
            lable={verificationHeadText}
            onChange={(e) => ChangeToken(e)}
          />
          <Field
            Id="NewPassword"
            type="text"
            lable={NewPassWordLableText}
            onChange={(e) => ChangeNewPass(e)}
          />
          <a onClick={ResendToken} className="fotget-pass-text">
            {t(DontSendTokenText)}
          </a>
          <a onClick={ChangePassWord} className="primary-btn mt-3 auto">
            {t(AddPlayableSubmitText)}
          </a>
          {ErrorAlert === true && loadingstate === false ? (
            <ErrorPopUp ErrorText={errMsg} />
          ) : null}
          {loadingstate === true ? <Loading /> : null}

          {Success === true ? <SuccessAlert Text={errMsg} /> : null}
        </form>
      </div>
    </div>
  );
};

export default SetNewPassword;
