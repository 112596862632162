import React, { Component } from 'react';

class Section extends Component {
    state = {}

    render() {
        return (
            <section className={this.props.styles}>
                <div className="container-fluid">
                    <div className="row">
                        {this.props.ChildElement}
                    </div>
                </div>
            </section>
        );
    }
}

export default Section;