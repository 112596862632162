import React from "react";
import { useTranslation } from "react-i18next";
const TextArea = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="form-group">
        <label for={props.Id}>{t(props.lable)}</label>
        <textarea
          id={props.Id}
          className="form-control"
          id={props.Id}
          rows="3"
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          placeholder={props.placeholder}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
