import React, { Component } from "react";
import TimeLine from "../TimeLine/TimeLine";
import LeftNav from "../../LeftNav/LeftNav";
import Section from "../../PanelSection/Section";
import Footer from "../DesigneAppFooter/DesignAppFooter";
import TemplatesCard from "../TemplatesCard/TemplatesCard";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import {
  ChooseTemplateQuestionText,
  ChooseTemplateDescription,
  ChooseTemplateButtonText,
  DesignAppMenuItemText,
} from "../../../Texts/Text";
class ChooseTemplate extends Component {
  state = {};

  render() {
    return (
      <>
        <Header title={DesignAppMenuItemText} />
        <LeftNav />
        <div className="dashboard-panel box-panel">
          <div>
            <div>
              <TimeLine PageNum="1" />
              <TemplatesCard />
            </div>
          </div>
          {/*  <p className="pt-3"></p> */}
        </div>
      </>
    );
  }
}

export default ChooseTemplate;
