import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { LoginHeadText, RegisterHeadText } from "../../Texts/Text";
import "./FormHead.css";
const FormHead = () => {
  const { t } = useTranslation();
  return (
    <div className="login-form-head">
      <NavLink exact activeClassName="active-form-head" to="/panel/login/">
        {t(LoginHeadText)}
      </NavLink>
      <NavLink exact activeClassName="active-form-head" to="/panel/register/">
        {t(RegisterHeadText)}
      </NavLink>
    </div>
  );
};

export default FormHead;
<div></div>;
