import React, { useContext, useEffect, useState } from "react";
import {
  PlayableData,
  CreateFolderName,
} from "../DesignAppStepsComponents/DesignApp";
import {
  BaseUrl,
  VerifyPayRequest,
  ProfilrInfo,
  GetPlayableapi,
  GetFactorwithClientRefId,
} from "../../../Texts/ApiUrl";
import { CallApi } from "../../CallApi/CallApi";
import {
  ComingSoon,
  ExportGameTitle,
  ExportGoogleAdTitleText,
  ExportReadyText,
  ExportUnityTitleText,
  ExportWebTitleText,
  GetGameLInk,
  GetSourceCodeText,
  GoToDashboardTextBtn,
} from "../../../Texts/Text";
import Loading from "../../../Tools/Loading/Loading";
import AssetImg from "../AssetImg/AssetImg";
import "./Export.css";
import { useTranslation } from "react-i18next";
const Export = () => {
  //const ThePlayable = useContext(PlayableData);
  const MainUrl = useContext(CreateFolderName);
  const search = window.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);

  useEffect(() => {
    const GetImportantData = async () => {
      //let CRId = localStorage.getItem("clientrefid");
      let CRId = params.get("clientRefId");
      let TheFactor = await Getfactor(CRId);
      setFactorInfo(TheFactor);
      setGamePreviewLink(
        "https://assets.wackotech.com/purchased_playable//" +
          TheFactor.directoryName
      );
      let playable = await localStorage.getItem("thePlayableId");
      let UserData = await GetUser();
      let ThePlayable = await GetPlayable(playable);
      console.log(ThePlayable);
      setthePlayable(ThePlayable);
      setTheUser(UserData);
      setInterval(() => {}, 10000);
      VerifyPay(playable, UserData.uuid, CRId);
      setpreload(false);
    };
    GetImportantData();
  }, []);
  const [preload, setpreload] = useState(true);
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [TheUser, setTheUser] = useState(false);
  const [thePlayable, setthePlayable] = useState(false);
  const [GamePreviewLink, setGamePreviewLink] = useState();
  const [FactorInfo, setFactorInfo] = useState();
  const VerifyPay = async (PlayableId, Useruuid, RefId) => {
    setloadingstate(true);
    var PlayableAssets = [];
    //PlayableAssets = await AddAsset();
    //console.log(Object.values(PlayableAssets));
    //const Assetsobj = Object.fromEntries(PlayableAssets);
    const allAssets = Object.assign({}, ...PlayableAssets);
    //console.log(allAssets);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    myHeaders.append("Content-Type", "application/json ");

    var raw = JSON.stringify({
      assets: allAssets,
      desc: {
        "this is extra1": "extra1",
        "this is extra2": "extra1",
        "this is extra3": "extra3",
      },
      clientRefId: RefId,
      folderPath: MainUrl,
      playableId: PlayableId,
      uuid: Useruuid,
    });
    //onsole.log(raw);
    const result = await CallApi(
      BaseUrl + VerifyPayRequest,
      raw,
      myHeaders,
      "POST"
    );
  };
  const GetUser = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };
  const GetPlayable = async (playableid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(
      BaseUrl + GetPlayableapi + "?id=" + playableid,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    } else {
      return {
        assets: [],
      };
    }
  };
  const Getfactor = async (crf) => {
    var Url = BaseUrl + GetFactorwithClientRefId + "?clientRefId=" + crf;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});

    const result = await CallApi(Url, raw, myHeaders, "GET");
    console.log(result);
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {preload === true ? (
        <Loading />
      ) : (
        <>
          <div
            className={
              thePlayable.landScape === true
                ? "col-12 export-game pt-4 p-1 site-direction"
                : "col-lg-8 col-md-6 export-game pt-4 p-1 site-direction"
            }
          >
            <h1 className="mt-3">{t(ExportGameTitle)}</h1>
            <div className="info-fac pt-5 pb-1 pl-4 pr-4">
              <p>{t(ExportReadyText)}</p>
              <div className="mt-4 pb-2">
                <p>{t(ExportWebTitleText)}</p>
                <p>
                  <a target="_blank" href={GamePreviewLink}>
                    {t(GetGameLInk)}
                  </a>
                </p>
              </div>
              <div className="mt-3 pb-2">
                <p>{t(ExportUnityTitleText)}</p>
                <p>
                  <a>{t(ComingSoon)}</a>
                </p>
              </div>
              <div className="mt-3 pb-2">
                <p>{t(ExportGoogleAdTitleText)}</p>
                <p>
                  <a>{t(ComingSoon)}</a>
                </p>
              </div>
            </div>
            <p>
              <a
                target="_blank"
                href={
                  "/panel/ticket/add?template=GetSource&url=" +
                  GamePreviewLink +
                  "&PlayableName=" +
                  thePlayable.name +
                  "&FactorId=" +
                  FactorInfo.id
                }
              >
                <i className="fa fa-comment" aria-hidden="true"></i>
                {t(GetSourceCodeText)}
              </a>
            </p>
          </div>
          <AssetImg LandScape={thePlayable.landScape} src={GamePreviewLink} />
        </>
      )}
      <div className="col-12">
        <div className="pl-5 redirect-btn mb-5">
          <a href="/panel/Dashboard" className="primary-btn">
            {t(GoToDashboardTextBtn)}
          </a>
        </div>
      </div>
    </>
  );
};

export default Export;
