import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./LeftNav.css";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  DashboardMenuItemText,
  ProfileMenuItemText,
  DesignAppMenuItemText,
  TicketMenuItemText,
  OrderSpecialGameText,
} from "../../Texts/Text";
import { useSelector } from "react-redux";
const LeftNav = (props) => {
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    useEffect(() => {
    let MenuState = localStorage.getItem("MenuState");
    var LeftNav = document.querySelector(".panel-nav");
    var Dashboard = document.querySelector(".dashboard-panel");
    var CloseBtn = document.querySelector(".close-menu-btn");
    if (lang === "en") {
      LeftNav.classList.add("nav-en");
      Dashboard.classList.add("dashboard-en");
    } else {
      LeftNav.classList.remove("nav-en");
      Dashboard.classList.remove("dashboard-en");
    }
    if (MenuState === "Close") {
      if (lang === "fa") {
        CloseBtn.classList.add("fa-arrow-left");
        CloseBtn.classList.remove("fa-arrow-right");
      } else {
        CloseBtn.classList.add("fa-arrow-right");
        CloseBtn.classList.remove("fa-arrow-left");
      }
      LeftNav.classList.add("active-menu");
      Dashboard.classList.add("full-dashboard");
    } else {
      LeftNav.classList.remove("active-menu");
      Dashboard.classList.remove("full-dashboard");
      if (lang === "fa") {
        CloseBtn.classList.add("fa-arrow-right");
        CloseBtn.classList.remove("fa-arrow-left");
      } else {
        CloseBtn.classList.remove("fa-arrow-right");
        CloseBtn.classList.add("fa-arrow-left");
      }
    }
  }, [lang]);
  const CloseMenu = () => {
    let MenuState = localStorage.getItem("MenuState");
    if (MenuState === "Open") {
      localStorage.setItem("MenuState", "Close");
    } else {
      localStorage.setItem("MenuState", "Open");
    }
    var LeftNav = document.querySelector(".panel-nav");
    var Dashboard = document.querySelector(".dashboard-panel");
    var CloseBtn = document.querySelector(".close-menu-btn");
    LeftNav.classList.toggle("active-menu");
    Dashboard.classList.toggle("full-dashboard");
    var CloseBtnClassList = CloseBtn.classList;

    if (CloseBtnClassList[2] == "fa-arrow-right") {
      CloseBtn.classList.remove("fa-arrow-right");
      CloseBtn.classList.add("fa-arrow-left");
    } else if (CloseBtnClassList[2] == "fa-arrow-left") {
      CloseBtn.classList.remove("fa-arrow-left");
      CloseBtn.classList.add("fa-arrow-right");
    }
  };
  const { t } = useTranslation();
  return (
    <div className="panel-nav">
      <ul className="left-nav">
        <li>
          <NavLink
            exact
            activeClassName="active-item"
            to="/panel/account-setting"
          >
            <i className="fa fa-user" aria-hidden="true"></i>
            <span>{t(ProfileMenuItemText)}</span>
          </NavLink>
        </li>
        <li>
          <NavLink exact activeClassName="active-item" to="/panel/dashboard">
            <i className="fa fa-line-chart" aria-hidden="true"></i>
            <span>{t(DashboardMenuItemText)}</span>
          </NavLink>
        </li>
        <li>
          <NavLink strict activeClassName="active-item" to="/panel/designapp/">
            <i className="fa fa-gamepad" aria-hidden="true"></i>
            <span>{t(DesignAppMenuItemText)}</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            exact
            activeClassName="active-item"
            to="/panel/order-spcialGame"
          >
            <i className="fa fa-magic" aria-hidden="true"></i>
            <span>{t(OrderSpecialGameText)}</span>
          </NavLink>
        </li>
        <li>
          <NavLink exact activeClassName="active-item" to="/panel/ticket">
            <i className="fa fa-comment" aria-hidden="true"></i>

            <span> {t(TicketMenuItemText)}</span>
          </NavLink>
        </li>
        <li>
          <a
            href={
              lang === "fa"
                ? "https://wackotech.com/"
                : "https://wackotech.com/"
            }
          >
            <i className="fa fa-home" aria-hidden="true"></i>

            <span> {t("BackToHome")}</span>
          </a>
        </li>
        <i
          className="fa close-menu-btn"
          onClick={CloseMenu}
          aria-hidden="true"
        ></i>
      </ul>
      <p className="pt-2"></p>
    </div>
  );
};

export default LeftNav;
