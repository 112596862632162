import React, { useState, useEffect } from "react";
import NumericalStatisticsBox from "../NumStatistics/NumStatistics";
import ChartsSection from "../Charts/ChartsStatistics";
import CustomerTable from "../Customers/Customers";
import { CallApi } from "../../CallApi/CallApi";
import {
  BaseUrl,
  GetAllPlayable,
  GetStatisticAllDataRequest,
  GetStatisticDataRequest,
} from "../../../Texts/ApiUrl";
import Loading from "../../../Tools/Loading/Loading";
import { useSelector } from "react-redux";
export const PlayableStatisticData = React.createContext();
const Body = () => {
  const [Statistics, setStatistics] = useState();
  const [preload, setpreload] = useState(true);
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    useEffect(() => {
    const Do = async () => {
      let AllPlayables = await GetAll();
      //console.log(AllPlayables);
      let StatisticList = [];
      for (let i = 0; i < AllPlayables.length; i++) {
        if (AllPlayables[i].packageName !== null) {
          let StatisticData = await GetStatistic(
            AllPlayables[i].id,
            AllPlayables[i].packageName
          );
          //console.log(StatisticData);
          if (StatisticData.statisticList !== null) {
            StatisticList.push(StatisticData);
          }
        }
      }
      setStatistics(StatisticList);
      setpreload(false);
    };
    Do();
  }, []);
  const GetAll = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    const result = await CallApi(
      BaseUrl + GetAllPlayable,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    }
  };
  const GetStatistic = async (Playableid, PackageName) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    const result = await CallApi(
      BaseUrl +
        GetStatisticAllDataRequest +
        "?packageName=" +
        PackageName +
        "&playableReferenceId=" +
        Playableid,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    }
  };

  return (
    <>
      <div>
        {preload === true ? (
          <Loading />
        ) : (
          <>
            <PlayableStatisticData.Provider value={Statistics}>
              <NumericalStatisticsBox data={Statistics} />
            </PlayableStatisticData.Provider>

            <PlayableStatisticData.Provider value={Statistics}>
              <ChartsSection data={Statistics} />
            </PlayableStatisticData.Provider>

            <CustomerTable data={Statistics} />
          </>
        )}
      </div>
    </>
  );
};

export default Body;
