import React, { Component } from "react";
import TimeLine from "../TimeLine/TimeLine";
import LeftNav from "../../LeftNav/LeftNav";
import Section from "../../PanelSection/Section";
import Footer from "../DesigneAppFooter/DesignAppFooter";
import EditAsset from "./../EditAsset/EditAsset";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import TimeLineWithOutMusic from "../TimeLine/TimeLineWithOutMusic";
import {
  ReplaceAssetQuestionText,
  ReplaceAssetDescription,
  ReplaceAssetButtonText,
  DesignAppMenuItemText,
} from "../../../Texts/Text";
const ReplaceAccet = (props) => {
  return (
    <>
      <Header title={DesignAppMenuItemText} />
      <LeftNav />
      <div className="dashboard-panel box-panel">
        <div className="pb-2">
          <div id="EditPlayableAssets">
            {props.havemusic === true ? (
              <TimeLine PageNum="2" />
            ) : (
              <TimeLineWithOutMusic PageNum="2" />
            )}

            <Section
              styles="ps-0 mt-5 site-direction"
              ChildElement={
                <EditAsset NextStep={props.child} LastPage={props.child2} />
              }
            />
          </div>
        </div>
        {/* <p className="pt-2"></p> */}
      </div>
    </>
  );
};

export default ReplaceAccet;
