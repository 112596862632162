import React from "react";
import { useTranslation } from "react-i18next";
import { SpecialLinkTimeLine } from "../../../Texts/Text";
import LeftNav from "../../LeftNav/LeftNav";
import Section from "../../PanelSection/Section";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import SpecialLink from "../SpecialLink/SpecialLink";
import TimeLine from "../TimeLine/TimeLine";
import TimeLineWithOutMusic from "../TimeLine/TimeLineWithOutMusic";
const ChooseLink = (props) => {
  return (
    <>
      <Header title={SpecialLinkTimeLine} />
      <LeftNav />
      <div className="dashboard-panel box-panel">
        <div>
          <div id="EditPlayableAssets">
            {props.havemusic === true ? (
              <TimeLine PageNum="4" />
            ) : (
              <TimeLineWithOutMusic PageNum="4" />
            )}
            <Section
              styles="ps-0 mt-5 site-direction"
              ChildElement={
                <SpecialLink NextStep={props.child} LastPage={props.child2} />
              }
            />
          </div>
        </div>
        {/*   <p className="pt-3"></p> */}
      </div>
    </>
  );
};

export default ChooseLink;
