export const BaseUrl =
  process.env.REACT_APP_API_URL || "https://dashboard.wackotech.com/api/";
export const VerifyEmail = "";
export const RegisterUser = "user/admin/register";
export const LoginUser = "user/login";
export const AddPlayableUrl = "playable/add";
export const AddAssetUrl = "asset/add";
export const GetAllPlayable = "playable/get-all";
export const GetPlayableapi = "playable/get";
export const SendTokenforgetPass = "user/get-forget-pass-token";
export const ReSendTokenforgetPass = "user/get-forget-pass-token";
export const ForgetPass = "user/forget-password";
export const ProfilrInfo = "user/profile";
export const DeleteAssetapi = "asset/delete";
export const AddUserPlayableApi =
  "user/preparation-playable/step-three/add-playable";
export const ChangePassApi = "user/change-password-in-app";
export const StepOneApi = "user/preparation-playable/step-one";
export const StepTwoApi = "user/preparation-playable/step-two";
export const AddTicketRequest = "ticket/add";
export const GetAllTicketsRequest = "ticket/get-all";
export const GetTicketRequest = "ticket/get";
export const UpadateTicketRequest = "ticket/update";
export const PayPingPayRequest = "pay-ping/pay";
export const VerifyPayRequest = "pay-ping/verify";
export const GetFacturesRequest = "purchase/get-all";
export const AddDefaultMusicRequest = "default-music/add";
export const GetTextTemplateRequest = "config/get";
export const GetFactorwithClientRefId = "purchase/get-by";
export const GetStatisticAllDataRequest = "statistic/get-all";
export const GetStatisticDataRequest = "statistic/get";
export const GetFactorWithDirectoryNameReq = "purchase/get-by-name";
export const ChangePhoneNumberRequest = "user/patch";
export const DeleteBgMusicRequest = "user/preparation-playable/delete-asset";
export const LoginWithGoogleRequest = "google/login";
export const ChangeCallToActionRequest =
  "user/preparation-playable/update-call-to-action";
export const ChangeUserDate = "user/patch-with-image";
