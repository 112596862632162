import React, { useState, useEffect } from "react";
import "./Customers.css";
import {
  CustomerTableTitle,
  CustomerRowOptionText,
  CustomerRowDetailsText,
  TomanText,
  ConfirmedText,
  unConfirmedText,
  GameTitleHeadTableText,
  ProceHeadTitleText,
  DataHeadTable,
  StateHeadTable,
  ExportGameHeadTable,
  NotFoundCustomers,
} from "../../../Texts/Text";
import { CallApi } from "../../CallApi/CallApi";
import {
  BaseUrl,
  ProfilrInfo,
  GetPlayableapi,
  GetFacturesRequest,
} from "../../../Texts/ApiUrl";
import Loading from "../../../Tools/Loading/Loading";
import Loading2 from "../../../Tools/Loading/Loading2";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const CustomerTable = () => {
  const [TheUser, setTheUser] = useState();
  const [playable, setplayable] = useState();
  const [Factures, setFactures] = useState();
  /*const [lang, setlang] = useState();*/
  const [preload, setpreload] = useState(false);

{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";


  useEffect(() => {
    const theCookie = Cookies.get("i18next");
    /* setlang(theCookie);  */
    const playableData = async () => {
      var data = await MyPlayables();
      //console.log(data);
      //console.log(data);

      var myFac = await GetMyFactures(data.uuid);

      await setTheUser(data);
      let CustomersList = [];
      //console.log(data.playableObjects);
      for (let i = 0; i < data.playableObjects.length; i++) {
        let Customer = {};
        let Name = await PlayableName(data.playableObjects[i].playableId);
        Customer["playableId"] = data.playableObjects[i].playableId;
        Customer["PlayableName"] = Name.name;
        Customer["Amount"] = Name.amount;
        let Verify = await GetVerifyState(
          myFac,
          data.playableObjects[i].url.split("/")[4]
        );
        Verify === true
          ? (Customer["Verify"] = ConfirmedText)
          : (Customer["Verify"] = unConfirmedText);

        Customer["Verify"] = ConfirmedText;
        //console.log(Verify);
        let options = { year: "numeric", month: "long", day: "numeric" };
        let date = null;
        if (theCookie === "fa") {
          date = new Date(
            data.playableObjects[i].createdDate * 1000
          ).toLocaleDateString("fa-IR", options);
        } else {
          date = new Date(
            data.playableObjects[i].createdDate * 1000
          ).toLocaleDateString("en-US", options);
        }
        /* console.log(date); */
        Customer["CreateDate"] = date;
        Customer["url"] = data.playableObjects[i].url;
        CustomersList.push(Customer);
      }

      CustomersList.reverse();
      await setplayable(CustomersList);
      setpreload(true);
    };

    playableData();
  }, [lang]);
  const e2p = (s) => s.replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
  const GetVerifyState = async (Factures, Directory) => {
    const found = await Factures.find((a) => a.directoryName === Directory);
    var Verify = null;
    if (typeof found !== "undefined") {
      if (found.verified === true) {
        Verify = true;
      } else {
        Verify = false;
      }
    } else {
      Verify = false;
    }
    //console.log(Verify);
    return Verify;
  };
  const MyPlayables = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/login";
    }
  };
  const PlayableName = async (Playableid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(
      BaseUrl + GetPlayableapi + "?id=" + Playableid,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      let data = {};
      data["name"] = result[0].data.name;
      data["amount"] = result[0].data.amount;
      return data;
    } else {
      return null;
    }
  };
  const GetMyFactures = async (uuid) => {
    var Url = BaseUrl + GetFacturesRequest + "?uuid=" + uuid;

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/login";
    }
  };
  const SetVerify = (bool) => {
    let result = null;
    {
      bool === ConfirmedText
        ? (result = <p className="confirmed-text"> {t(bool)}</p>)
        : (result = <p className="unconfirmed-text"> {t(bool)}</p>);
    }

    return result;
  };
  const { t } = useTranslation();
  return (
    <section id="CustomerItem" className="mt-5 mb-5 ps-0">
      {preload === false ? (
        <Loading2 />
      ) : (
        <div className="container-fluid p-0">
          <div className="row pr-2 pl-2">
            <div className="card-title">
              <h3>{t(CustomerTableTitle)}</h3>
            </div>
            <div className="col-12 p-0">
              <div className="tickets">
                <div>
                  <p>{t(GameTitleHeadTableText)}</p>
                  <p>{t(ProceHeadTitleText)}</p>
                  <p>{t(DataHeadTable)}</p>
                  <p>{t(StateHeadTable)}</p>
                  <p>{t(ExportGameHeadTable)}</p>
                </div>
                {playable.length > 0 ? (
                  playable.map((a) => (
                    <a>
                      <h2>{t(a.PlayableName)}</h2>
                      <h6>
                        {lang === "fa"
                          ? e2p(
                              new Intl.NumberFormat("en-IN", {
                                maximumSignificantDigits: 3,
                              }).format(a.Amount)
                            )
                          : new Intl.NumberFormat("en-IN", {
                              maximumSignificantDigits: 3,
                            }).format(a.Amount)}
                        <span>{t(TomanText)}</span>
                      </h6>

                      <h4>{a.CreateDate}</h4>
                      {SetVerify(a.Verify)}
                      <div>
                        <a
                          href={
                            "/panel/dashboard/detailgame?url=" +
                            a.url +
                            "&playableid=" +
                            a.playableId
                          }
                          target="_blank"
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </a>
                      </div>
                    </a>
                  ))
                ) : (
                  <a className="no-exist">
                    <p>{t(NotFoundCustomers)}</p>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default CustomerTable;
