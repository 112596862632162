import React, { useState, useEffect } from "react";
import Field from "../../../Tools/Inputs/input";
import {
  TicketTitlePlaceholder,
  AddPlayableSubmitText,
  ChooseFileUploadText,
  priorityDropDownLableText,
  TypeOfTicketDropDownLableText,
  TicketTextPlaceholderText,
  LimitSize,
  LargesImageInAddAsset,
  ErrorAddAsset,
  error,
  ticketTemplateGetSourceTitle,
  IncompleteDataErrorText,
  ticketTemplateGetSourceDes,
  TicketPageTitleText,
  AddTicketBtnText,
  CancelText,
} from "../../../Texts/Text";
import { CallApi } from "../../CallApi/CallApi";
import {
  BaseUrl,
  ProfilrInfo,
  AddAssetUrl,
  AddTicketRequest,
  GetTextTemplateRequest,
} from "../../../Texts/ApiUrl";
import {
  priorityoneText,
  priorityTwoText,
  priorityThreeText,
  priorityFourText,
  priorityFiveText,
  TypeOne,
  TypeOneValue,
  TypeOneText,
  TypeTwoValue,
  TypeTwotext,
} from "../../../Texts/Options";
import Loading from "../../../Tools/Loading/Loading";
import TextArea from "../../../Tools/TextArea/TextArea";
import FileUpload from "../../../Tools/FileUpload/FileUpload";
import "./AddTicket.css";
import ErrorPopUp from "../../../ErrorPopUp/ErrorPopUp";
import LeftNav from "../../LeftNav/LeftNav";
import { useTranslation } from "react-i18next";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import Cookies from "js-cookie";
const AddTicket = (props) => {
  const search = window.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  var template = params.get("template");
  var type = props.Type;
  useEffect(() => {
    const SetImportandData = async () => {
      let TicketType = document.getElementById("TypeOfTicket");
      TicketType.value = type;
      if (template !== null) {
        let el = document.getElementById("TicketTitle");
        let elDes = document.getElementById("TicketDescription");

        if (template === "GetSource") {
          //title
          setType(TypeOneValue);
          TicketType.value = TypeOneValue;
          let TemplateTitle = await GetTemplateText(
            "giveSourceCodeRequestTitle"
          );
          var url = params.get("url");
          TemplateTitle = TemplateTitle.replaceAll("purchasedGameUrl", url);
          el.value = TemplateTitle;
          //description
          let TemplateDescription = await GetTemplateText(
            "giveSourceCodeRequestText"
          );
          var playableName = params.get("PlayableName");
          var FactorId = params.get("FactorId");
          TemplateDescription = TemplateDescription.replaceAll(
            "wackoGameUrl",
            playableName
          )
            .replaceAll("purchasedGameUrl", url)
            .replaceAll("factorId", FactorId);

          elDes.value = TemplateDescription;
          setText(TemplateDescription);
          setTicketTitle(TemplateTitle);

          setpriority(3);
        }
      }
      let Propertie = document.getElementById("priorityTicket");
      Propertie.value = 3;
      setType(type);
      setpriority(3);
      let UserData = await GetUser();
      setTheUser(UserData);

      setpreload(true);
    };
    SetImportandData();
  }, []);

  const [TicketTitle, setTicketTitle] = useState();
  const [priority, setpriority] = useState();
  const [Type, setType] = useState();
  const [TheUser, setTheUser] = useState();
  const [preload, setpreload] = useState(false);
  const [Text, setText] = useState(false);
  const [ErrorAlert, setErrorAlert] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [errMsg, seterrMsg] = useState();
  const [fileUpload, setfileUpload] = useState(null);
  const ChangeTitle = async (el) => {
    setTicketTitle(el.target.value);
  };
  const AddAsset = async () => {
    if (fileUpload !== null) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("UserToken"));

      var formdata = new FormData();
      formdata.append("file", fileUpload.DataFile);
      formdata.append("data", '{"name":"' + fileUpload.DataName + '"}');

      const result = await CallApi(
        BaseUrl + AddAssetUrl,
        formdata,
        myHeaders,
        "POST"
      );

      if (result[1] === 200) {
        setErrorAlert(false);
        //setloadingstate(false);
        result[0].data.assets.fileUrl =
          result[0].data.baseAssetPath + result[0].data.assets.fileUrl;
        return result[0].data.assets.fileUrl;
      } else if (result[1] === 403) {
        setErrorAlert(false);
        setloadingstate(false);
        window.location = "/panel/login";
      } else if (result[1] === 413) {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(LargesImageInAddAsset);
        return "";
      } else {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(ErrorAddAsset);
        return "";
      }
    } else {
      return "";
    }

    //console.log(Assets);
  };
  const AddTicketClick = async () => {
    setloadingstate(true);
    if (
      Text !== null &&
      Text !== "" &&
      TicketTitle !== null &&
      TicketTitle !== ""
    ) {
      let file = await AddAsset();

      /* console.log(file);
      var timespan = Math.floor(new Date().getTime() / 1000.0); */
      /* let _user = "user_" + timespan; */
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("UserToken")
      );
      myHeaders.append("Content-Type", "application/json");
      let Messages = {};
      Messages["user"] = Text;

      var raw = JSON.stringify({
        emailSender: TheUser.email,
        fileUrl: file,
        messages: Messages,
        priority: priority,
        subject: TicketTitle,
        ticketState: "state_one",
        ticketType: Type,
        uuidSender: TheUser.uuid,
      });

      const result = await CallApi(
        BaseUrl + AddTicketRequest,
        raw,
        myHeaders,
        "POST"
      );
      if (result[1] === 200) {
        setloadingstate(false);
        window.location = "/panel/Ticket/edit?id=" + result[0].data.id;
      } else {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(error);
      }
    } else {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(IncompleteDataErrorText);
    }
  };
  const UploadFile = async (el) => {
    let limitSize = el.target.files[0].size / 1024;
    let lable = document.getElementById("UploadLable");
    if (limitSize < 2048) {
      let Asset = {};
      Asset["DataFile"] = el.target.files[0];
      Asset["DataName"] = "ticketFile";
      lable.innerHTML = el.target.files[0].name;
      setfileUpload(Asset);
    } else {
      setErrorAlert(true);
      seterrMsg(LimitSize);
    }
  };
  const GetUser = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };
  const Changepriority = (el) => {
    setpriority(el.target.value);
  };
  const ChangeTypeOfTicket = (el) => {
    setType(el.target.value);
  };
  const ChangeTicketText = (el) => {
    setText(el.target.value);
  };
  const GetTemplateText = async (templateItem) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    const result = await CallApi(
      BaseUrl + GetTextTemplateRequest + "?key=" + templateItem,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    } else {
      return null;
    }
  };

  const { t } = useTranslation();
  return (
    <>
      <Header title={t(TicketPageTitleText)} />
      <LeftNav />
      <div className="dashboard-panel box-panel ticket-box-panel">
        <div>
          <div>
            <div className="col-md-8 col-sm-11 col-12 auto mt-5 ticket">
              <h1 className="title">{t(AddTicketBtnText)}</h1>
              {preload === true ? null : <Loading />}
              <Field
                Id="TicketTitle"
                type="text"
                placeholder={t("TicketTitlePlaceholder")}
                lable={TicketTitlePlaceholder}
                onChange={(e) => ChangeTitle(e)}
              />
              <div className="form-group mt-3">
                <label>{t(priorityDropDownLableText)}</label>
                <select
                  className="custom-select my-1 "
                  id="priorityTicket"
                  onChange={(e) => Changepriority(e)}
                >
                  <option selected="selected">
                    {t(priorityDropDownLableText)}
                  </option>
                  <option value="1">{t(priorityoneText)}</option>
                  <option value="2">{t(priorityTwoText)}</option>
                  <option value="3">{t(priorityThreeText)}</option>
                  <option value="4">{t(priorityFourText)}</option>
                  <option value="5">{t(priorityFiveText)}</option>
                </select>
              </div>
              <div className="form-group mt-3">
                <label>{t(TypeOfTicketDropDownLableText)}</label>
                <select
                  className="custom-select my-1 mt-1"
                  id="TypeOfTicket"
                  onChange={(e) => ChangeTypeOfTicket(e)}
                >
                  <option selected="selected">
                    {t(TypeOfTicketDropDownLableText)}
                  </option>
                  <option value={TypeOneValue}>{t(TypeOneText)}</option>
                  <option value={TypeTwoValue}>{t(TypeTwotext)}</option>
                </select>
              </div>
              <div className="form-group mt-3">
                <label>{t(ChooseFileUploadText)}</label>
                <FileUpload
                  id={"UploadLable"}
                  lable={"FileUploadPlaceholder"}
                  Change={(e) => UploadFile(e)}
                />
              </div>

              <TextArea
                Id="TicketDescription"
                lable={t(TicketTextPlaceholderText)}
                placeholder={t("TicketMessagePlaceholder")}
                onChange={(e) => ChangeTicketText(e)}
              />

              <div className="redirect-btn mt-5">
                <a onClick={AddTicketClick} className="primary-btn mt-0 auto">
                  {t(AddPlayableSubmitText)}
                </a>
                <a href="/panel/dashboard" className="reverce-primary-btn auto">
                  {t(CancelText)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {ErrorAlert === true && loadingstate === false ? (
        <ErrorPopUp ErrorText={errMsg} />
      ) : null}
      {loadingstate === true ? <Loading /> : null}
    </>
  );
};

export default AddTicket;
