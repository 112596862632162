import Cookies from "js-cookie";
const initiailState = {
  lang: "en",
};
const LangReducer = (state = initiailState, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        lang: action.payload,
      };
    default:
      return state;
  }
};
export default LangReducer;
