import React, { Component } from "react";
import "./PanelFooter.css";
import { DashboardCopyRightText } from "../../Texts/Text";
class Footer extends Component {
  state = {};

  render() {
    return (
      <div className="panel-foot">
        <h6>
          <i className="fa fa-copyright" aria-hidden="true"></i>
          {DashboardCopyRightText}
        </h6>
      </div>
    );
  }
}

export default Footer;
