import React, { useState, useEffect } from "react";
import "./Verify.css";
import {
  verificationHeadText,
  verificationBtnText,
  VerifyCodeisWrong,
  error,
  AddPlayableSubmitText,
  ResendTokenForgetPass,
  VerifyTitle,
  ResendCodeText,
} from "../../Texts/Text";
import Field from "../../Tools/Inputs/input";
import { CallApi } from "../../PanelComponent/CallApi/CallApi";
import ErrorPopUp from "../../ErrorPopUp/ErrorPopUp";
import Loading from "../../Tools/Loading/Loading";
import { BaseUrl } from "../../Texts/ApiUrl";
import SuccessAlert from "../../ErrorPopUp/SuccessAlert";
import { useTranslation } from "react-i18next";
const Verify = () => {
  const [ErrorAlert, setErrorAlert] = useState();
  const [SuccessText, setSuccessText] = useState(false);
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [AuCode, setAuCode] = useState(null);
  const [EndTime, setEndTime] = useState(false);
  const [EndTimeText, setEndTimeText] = useState(false);
  const [Estimate, setEstimate] = useState(59);
  useEffect(() => {
    UpdateTimer();
  }, [Estimate]);
  const veryfyAction = async () => {
    setloadingstate(true);
    setSuccessText(false);
    setErrorAlert(false);
    var Email = localStorage.getItem("UserEmail");
    console.log(AuCode);
    if (AuCode != null) {
      var Url =
        BaseUrl + "user/verify?email=" + Email + "&verificationCode=" + AuCode;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({});

      const result = await CallApi(Url, raw, myHeaders, "GET");

      if (result[1] === 200) {
        setErrorAlert(false);
        setloadingstate(false);
        window.location = "/panel/login";
      } else if (result[1] === 403) {
        setloadingstate(false);
        seterrMsg(VerifyCodeisWrong);
        setErrorAlert(true);
      } else {
        setloadingstate(false);
        seterrMsg(error);
        setErrorAlert(true);
      }
    } else {
      setloadingstate(false);
      seterrMsg(VerifyCodeisWrong);
      setErrorAlert(true);
    }
  };
  const ChangeCodeField = async (el) => {
    setAuCode(el.target.value);
  };
  const UpdateTimer = () => {
    if (EndTime === false) {
      Estimate > 0 &&
        setTimeout(() => {
          setEstimate(Estimate - 1);
          setEndTimeText("00:" + Estimate);
        }, 1000);
      if (Estimate === 0) {
        var el = document.getElementById("Estimate");
        el.classList.add("pointer");
        setEndTime(true);
        setEstimate(0);
        setEndTimeText("ارسال مجدد");
      }
    }
  };
  const SenAgainCode = async () => {
    setSuccessText(false);
    if (EndTime === true) {
      var el = document.getElementById("Estimate");
      el.classList.remove("pointer");
      setEndTime(false);
      setEstimate(59);
      var Email = localStorage.getItem("UserEmail");
      var Url = BaseUrl + "user/regenerate-verification-code?email=" + Email;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({});
      const result = await CallApi(Url, raw, myHeaders, "GET");
      if (result[1] === 200) {
        setErrorAlert(false);
        setloadingstate(false);
        seterrMsg(ResendTokenForgetPass);
        setSuccessText(true);
        /*  window.location = "/login"; */
      } else {
      }
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-6 col-md-8 col-12 mt-5 p-0 form auto">
        <div className="form-body pt-5 pb-5">
          <h5>{t(VerifyTitle)}</h5>

          <form>
            <Field
              Id="VerifyCode"
              aria="emailHelp"
              type="text"
              lable={verificationHeadText}
              onChange={(e) => ChangeCodeField(e)}
            />
            <a onClick={veryfyAction} className="primary-btn mt-2 auto">
              {t(AddPlayableSubmitText)}
            </a>
            {ErrorAlert === true && loadingstate === false ? (
              <ErrorPopUp ErrorText={errMsg} />
            ) : null}
            {loadingstate === true ? <Loading /> : null}
          </form>
          <div className="timer mt-5">
            <p>{t(ResendCodeText)}</p>
            <span id="Estimate" onClick={SenAgainCode}>
              {EndTimeText}
            </span>
          </div>
        </div>
      </div>
      {SuccessText === true ? <SuccessAlert Text={errMsg} /> : null}
    </>
  );
};

export default Verify;
