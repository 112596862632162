import React from "react";
import LeftNav from "../../LeftNav/LeftNav";
import TimeLine from "../TimeLine/TimeLine";
import Section from "../../PanelSection/Section";
import Facture from "../Facture/Facture";
import { DesignAppMenuItemText } from "../../../Texts/Text";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import TimeLineWithOutMusic from "../TimeLine/TimeLineWithOutMusic";
const Payment = (props) => {
  return (
    <>
      <Header title={DesignAppMenuItemText} />
      <LeftNav />
      <div className="dashboard-panel box-panel">
        <div>
          <div>
            {props.havemusic === true ? (
              <TimeLine PageNum="5" />
            ) : (
              <TimeLineWithOutMusic PageNum="5" />
            )}

            <Section
              styles="ps-0 mt-5 site-direction"
              ChildElement={
                <Facture PayBtn={props.child} LastPage={props.child2} />
              }
            />
          </div>
        </div>
        {/*      <p className="pt-3"></p> */}
      </div>
    </>
  );
};

export default Payment;
