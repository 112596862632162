import React from "react";
import { useTranslation } from "react-i18next";
import warning from "../Images/warning.png";
const ExitPopUp = (props) => {
  const { t } = useTranslation();
  return (
    <div className="exit-box">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-8 col-sm-10 col-12 auto exit-mesasge p-4">
            <div className="title-exit mt-3">
              <div>
                <img src={warning} />
              </div>
              <h2>{t("ExitFromPanel")}</h2>
            </div>
            <p>{t("ExitAccountWarning")}</p>
            <div className="redirect-btn mt-5">
              {props.exit}
              {props.cancel}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitPopUp;
