import Cookies from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  DateText,
  FacturePageTitle,
  FacturePriceTitleText,
  PaymentWarning,
  TomanText,
} from "../../../Texts/Text";
import AssetImg from "../AssetImg/AssetImg";
import {
  CreateFolderName,
  PlayableData,
} from "../DesignAppStepsComponents/DesignApp";
import "./Facture.css";
const Facture = (props) => {
  const ThePlayable = useContext(PlayableData);
  const MainUrl = useContext(CreateFolderName);
  const [GamePreviewLink, setGamePreviewLink] = useState();
  /* const [lang, setlang] = useState(); */
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

  useEffect(() => {
    //console.log(data);
    /*  const theCookie = Cookies.get("i18next");
    setlang(theCookie); */
    setGamePreviewLink(
      "https://assets.wackotech.com/purchased_playable//" + MainUrl.mainFolder
    );
  }, []);
  const e2p = (s) => s.replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);
  const persianDate = () => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    return new Date().toLocaleDateString("fa-IR", options);
  };
  const EnData = () => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    return new Date().toLocaleDateString("en-US", options);
  };
  const { t } = useTranslation();
  return (
    <>
      <>
        <div
          className={
            ThePlayable.landScape
              ? "col-12 facture-panel p-1"
              : "col-lg-9 col-md-6 facture-panel p-1"
          }
        >
          {/*   <h1 className="mt-5">{t(FacturePageTitle)}</h1> */}
          <div className="info-fac pt-5 pb-5 p-4">
            <h2>
              {t(ThePlayable.name)}
              <span>({t(ThePlayable.category.name)})</span>
            </h2>
            <div className="mt-4">
              <p>{t(FacturePriceTitleText)}</p>
              <p>
                <span>{t(TomanText)}</span>
                <h6>
                  {lang === "fa"
                    ? e2p(
                        new Intl.NumberFormat("en-IN", {
                          maximumSignificantDigits: 3,
                        }).format(ThePlayable.amount)
                      )
                    : new Intl.NumberFormat("en-IN", {
                        maximumSignificantDigits: 3,
                      }).format(ThePlayable.amount)}
                </h6>
              </p>
            </div>
            <div className="mt-3">
              <p>{t(DateText)}</p>
              <p>
                <span>{lang === "fa" ? e2p(persianDate()) : EnData()}</span>
              </p>
            </div>
          </div>
          <p>{t(PaymentWarning)}</p>
        </div>
        {ThePlayable.landScape === true ? (
          <div className="col-12 mt-1 redirect-btn">
            {props.LastPage}
            {props.PayBtn}
          </div>
        ) : null}
        <AssetImg LandScape={ThePlayable.landScape} src={GamePreviewLink} />
      </>
      {ThePlayable.landScape === false ? (
        <div className="col-12 fixed-nav-bottom pt-2">
          <div className="redirect-btn">
            {props.LastPage}
            {props.PayBtn}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Facture;
