export const EditTextTitle = "Customize The Texts";
export const EditAssetTitle = "Choose_your_asets";
export const CopyRightDesignApp1 =
  "Privacy Policy - Terms Of Service All trademarks  ";
export const CopyRightDesignApp2 = " their respective owners";
export const AccountSettingText = "Account Setting";
export const EditAssetText = "Edit";
export const FilterItem1 = "Record";
export const FilterItem2 = "Musical";
export const FilterItem3 = "Optional";
export const FilterItem4 = "Hunting";
export const FilterItem5 = "Application";
export const CustomerTableTitle = "Recent_orders";
export const CustomerRowOptionText = "Option";
export const CustomerRowDetailsText = "Details";
export const PieChartTitle = "Game_Visits";
export const LineChartTitle = "User Number - Per Playable Advertisemen";
export const DashboardCopyRightText = "Your Company Details";
export const TimeLineChooseTemplateText = "ChooseTemplate";
export const TimeLineReolaceAssetText = "ChangeAssets";
export const TimeLineMusicText = "ChooseMusic";
export const TimeLinePayment = "Pay";
export const TimeLineExport = "Export";
export const ChooseTemplateQuestionText =
  "You couldn`t find any suitable template ?!";
export const ChooseTemplateDescription =
  "Or is this hard for you to find nice assets?!";
export const ChooseTemplateButtonText = "Order an Interactive Ad";
export const ReplaceAssetQuestionText =
  "You couldn`t find any suitable template ?!";
export const ReplaceAssetDescription =
  "Or is this hard for you to find nice assets?!";
export const ChooseMusicFooterQuestionText =
  "You couldn`t find any suitable template ?!";
export const ChooseMusicFooterDescription =
  "Or is this hard for you to find nice assets?!";
export const ReplaceAssetButtonText = "Order an Interactive Ad";
export const ChooseMusicFooterButtonText = "Order an Interactive Ad";
export const chooseMusicTitle = "Choose_music";
export const ChooseMusicText = "ChooseMusic";
export const RemoveMusicText = "RemoveMusic";
export const RemoveAssetText = "RemoveAsset";
export const ApiErrorMessage = "Warrning !! Please ..";
export const LoginHeadText = "Login";
export const RegisterHeadText = "Register";
export const LoginBtnText = "Login_to_panel";
export const PasswordPlaceholderText = "Enter_your_password";
export const EmailPlaceholderText = "Enter_Your_Email";
export const EmailLable = "Email";
export const PasswordLable = "Password";
export const verificationHeadText = "Verification_code";
export const verificationBtnText = "Send";
export const EmailNotFoundText = "Wrong_Email";
export const PasswordInvalidText = "EmailOrPassword_Wrong";
export const error = "something_went_wrong";
export const EmailExisterrorText = "EmailExist";
export const EmailInvaliderrorText = "InvalidEmail";
export const VerifyCodeisWrong = "Wrong_verification_code";
export const PlayableUrlInputLable = "Address";
export const PlayableNameInputLable = "Game_title";
export const PlayableFilesInputLable = "Choose_game_files";
export const PlayableCategoryInputLable = "Category";
export const PlayableIdInputLable = "ID";
export const AddPlayableSubmitText = "Submit";
export const LargesImageInAddAsset = "Large_asset_size";
export const ErrorAddAsset = "Fail_to_upload_assets";
export const DataInvalid = "Invalid_data";
export const NewPassWordLableText = "EnterNewPass";
export const OldPassWordLableText = "EnterOldPass";
export const RedirectChooseMusciText = "ChooseMusic";
export const RedirectPayPageText = "Confirm";
export const FacturePageTitle = "Bill";
export const NextPage = "Next_page";
export const LastPage = "Previus_page";
export const PayBtnText = "Let_Pay";
export const SuccessText = "Successfull_transaction";
export const InvalidPasswordErrorText = "Invali_password";
export const SamePassword = "ExistPassword";
export const ForgetPassText = "Forgot_password";
export const DontSendTokenText = "didnt_send";
export const ResendTokenForgetPass = "sent_code";
export const LimitSize = "Bigar_than";
export const TicketTitlePlaceholder = "Ticket_title";
export const ChooseFileUploadText = "choose_your_file";
export const priorityDropDownLableText = "Ticket_priority";
export const TypeOfTicketDropDownLableText = "Ticket_type";
export const TicketTextPlaceholderText = "Ticket_content";
export const AddTicketBtnText = "New_ticket";
export const CloseTicketEnumText = "close";
export const OpenTicketEnumText = "state_one";
export const CloseTicketTxt = "Closed";
export const OpenTicketTxt = "Open";
export const ReplayTicketTextAreaPlaceholder = "Reply";
export const EmptyTicketMessage = "No_ticket_content";
export const IncompleteDataErrorText = "Complete informations";
export const ComeBackBtnText = "Back";
export const SuccessPayTextAlert = "Success_Pay";
export const TicketsListTextBtn = "Tickets";
export const DashboardMenuItemText = "Dashboard";
export const ProfileMenuItemText = "Profile";
export const DesignAppMenuItemText = "DesignApp";
export const TicketMenuItemText = "Messages";
export const EditThisTemplate = "Select";
export const ThisTemplateSelected = "Selected";
export const AllContent = "AllGame";
export const FacturePriceTitleText = "Required_payment";
export const TomanText = "Toman";
export const DateText = "Date";
export const PaymentWarning = "Final_Pay_warning";
export const ExportReadyText = "Share_Game_Link";
export const ExportWebTitleText = "Game_Link";
export const ExportUnityTitleText = "Unity_Ad";
export const ExportGoogleAdTitleText = "Google_Ad";
export const ExportGameTitle = "Export_Game";
export const GetGameLInk = "Recive_link";
export const ComingSoon = "Coming_soon";
export const ConfirmedText = "Comfirmed";
export const unConfirmedText = "UnConfirmed";
export const GetSourceCodeText = "GetSource";
export const ChooseGameText = "Select_the_game";
export const FullScreenbtnText = "Full_screen_view";
export const UplaodText = "Upload";
export const GameTitleHeadTableText = "Game_title";
export const ProceHeadTitleText = "Price";
export const DataHeadTable = "Header_Date";
export const StateHeadTable = "State";
export const ExportGameHeadTable = "Show_game";
export const GoToDashboardTextBtn = "Return_to_dashboard";
export const OrderSpecialGameText = "DesigneSpecialGame";
export const TicketPageTitleText = "Ticket";
//export const ticketTemplateGetSourceTitle = "لورم ایپسوم متن ساختگی";
//export const ticketTemplateGetSourceDes = "لورم ایپسوم متن ساختگی";
export const shareCountText = "Share_count";
export const addUserNameCountText = "Registered_users";
export const clickDownloadFromStoreCountText = "link_opened";
export const ViewText = "Visit_count";
export const GamePlayCount = "Game_Played";
export const insertRecordText = "Registered_users";
export const StatisticsGamesTitleText = "Game_statistics";
export const GameDetailTitlePage = "Game_details";
export const FullNameRegisterText = "FullName";
export const PhoneNumberFieldLable = "Mobile_Number";
export const WebSiteAddressFieldLable = "Website_address";
export const SubjectRegisterOptionLable = "Field";
export const ErrorReqiredField = "Required_field";
export const ChnagePassInProfileTitle = "Change_password";
export const ChangePhoneNumberInProfileTitle = "Edit_Mobile_number";
export const ChangePhoneFaild = "An_error_mobile_number";
export const CancelText = "Ignor";
export const WaitingText = "Please_wait";
export const FaildPayText = "An_error_occured";
export const LoginwithGoogleText = "Login_witt_Google";
export const UserTitleText = "user";
export const AdminTitleText = "admin";
export const VerifyTitle = "VerifyTitle";
export const ResendCodeText = "ResendCode";
export const DeleteMusicCheckBoxLabel = "RemoveMusic";
export const InvalidFormatPhoneText = "InvalidFormat";
export const LimitFileUploadText = "LimitFileUpload";
export const LoginwithEmailFormTitle = "LoginWithEmail";
export const RegisterwithEmailFormTitle = "RegisterWithEmail";
export const ChooseCreateAcount = "ChooseCreateAcount";
export const QuestiontextForCreateAcount = "haveAcount";
export const IhaveAcount = "IhaveAcount";
export const SpecialLinkTimeLine = "SpecialLink";
export const SpecialLinkLable = "SpecialLinkLable";
export const SpecialLinkPlaceholder = "SpecialLinkPlaceholder";
export const NotFounTicket = "NotFounTicket";
export const NotFoundCustomers = "NotFoundCustomers";
export const UserNameText = "UserNameText";
export const PlaceholderMessage = "EnterMessage";
