import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  clickDownloadFromStoreCountText,
  GamePlayCount,
  insertRecordText,
  LineChartTitle,
  shareCountText,
  StatisticsGamesTitleText,
  ViewText,
} from "../../../Texts/Text";
import { PlayableStatisticData } from "../ProfileBody/ProfileBody";

const LineChart = (props) => {
  const StatisticData = useContext(PlayableStatisticData);
  const [ChartData, setChartData] = useState();
  /*   const [lang, setlang] = useState(); */
  const [preload, setpreload] = useState();
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    useEffect(() => {
    /*   const theCookie = Cookies.get("i18next");
    setlang(theCookie); */
  }, []);
  const { t } = useTranslation();
  return (
    <div className="col-md-8 mt-1 p-2">
      <div className="dark-box p-3">
        <div className="chart-title">
          <h3>{props.title}</h3>
        </div>
        {lang === "fa" ? (
          <Bar
            data={props.data}
            options={{
              responsive: true,
              title: {
                display: false,
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "top",
              },
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                  align: "end",
                  labels: {
                    padding: 15,
                    usePointStyle: true,
                    // This more specific font property overrides the global property
                    font: {
                      family: "IRANSansWeb",
                    },
                  },
                },
                tooltip: {
                  bodyFont: {
                    family: "IRANSansWeb",
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      family: "IRANSansWeb",
                    },
                  },
                },
              },
            }}
          />
        ) : (
          <Bar
            data={props.data}
            options={{
              responsive: true,

              title: {
                display: false,
                fontSize: 20,
              },
              legend: {
                display: false,
                position: "top",
              },
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                  align: "start",
                  labels: {
                    padding: 15,
                    usePointStyle: true,
                    // This more specific font property overrides the global property
                    font: {
                      family: "Roboto",
                    },
                  },
                  tooltip: {
                    bodyFont: {
                      family: "Roboto",
                    },
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    font: {
                      family: "Roboto",
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LineChart;
