import React, { Component } from "react";
import "./Field.css";
import { useTranslation } from "react-i18next";
const Field = (props) => {
  const { t } = useTranslation();
  return (
    <div className="form-group mt-3">
      <label for={props.Id}>
        {t(props.lable)}
        {props.Required === true ? <span aria-hidden="true">*</span> : null}
      </label>

      <input
        type={props.type}
        className="form-control"
        maxlength={props.maxlength}
        id={props.Id}
        aria-describedby={props.describedby}
        placeholder={t(props.placeholder)}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        readOnly={props.readonly}
      />
      {props.icon}
    </div>
  );
};

export default Field;
