import React, { useState, useEffect } from "react";
import "./Tickets.css";
import Loading from "../../../Tools/Loading/Loading";
import { CallApi } from "../../CallApi/CallApi";
import {
  BaseUrl,
  GetAllTicketsRequest,
  ProfilrInfo,
} from "../../../Texts/ApiUrl";
import {
  AddTicketBtnText,
  OpenTicketTxt,
  CloseTicketTxt,
  CloseTicketEnumText,
  OpenTicketEnumText,
  TicketMenuItemText,
  GameTitleHeadTableText,
  ProceHeadTitleText,
  TicketTitlePlaceholder,
  TypeOfTicketDropDownLableText,
  priorityDropDownLableText,
  StateHeadTable,
  NotFounTicket,
  DateText,
  DataHeadTable,
} from "../../../Texts/Text";
import LeftNav from "../../LeftNav/LeftNav";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { TypeOneValue } from "../../../Texts/Options";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
const Tickets = (props) => {
  const langState = useSelector((state) => state.LangState.lang);

  useEffect(() => {
    const theCookie = Cookies.get("i18next");
    setlang(theCookie);
    const LoadImportantData = async () => {
      let UserData = await GetUser();
      setTheUser(UserData);
      let Tickets = await GetTickets(UserData.uuid);
      Tickets.reverse();

      setTheTickets(Tickets);
      setpreload(false);
    };
    LoadImportantData();
  }, []);
  const [lang, setlang] = useState();
  const [TheUser, setTheUser] = useState();
  const [TheTickets, setTheTickets] = useState();
  const [preload, setpreload] = useState(true);
  const GetUser = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };
  const StateText = (Ticket) => {
    let text = null;
    {
      Ticket === OpenTicketEnumText
        ? (text = OpenTicketTxt)
        : (text = CloseTicketTxt);
    }
    return text;
  };
  const GetTickets = async (userid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    var raw = JSON.stringify({});
    const result = await CallApi(
      BaseUrl +
        GetAllTicketsRequest +
        "?uuidSender=" +
        userid +
        "&ticketType=" +
        props.Type,
      raw,
      myHeaders,
      "GET"
    );
    console.log(
      BaseUrl +
        GetAllTicketsRequest +
        "?uuidSender=" +
        userid +
        "&ticketType=" +
        props.Type
    );
    if (result[1] === 200) {
      return result[0].data;
    } else {
      return null;
    }
  };
  const SetPriority = (i) => {
    switch (i) {
      case 1:
        return "VeryLow";
        break;
      case 2:
        return "Low";
        break;
      case 3:
        return "Middle";
        break;
      case 4:
        return "Hight";
        break;
      case 5:
        return "VeryHight";
        break;

      default:
        return "Middle";
    }
  };
  const SetDate = (timestamp) => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    var data2 = null;
    if (langState === "fa") {
      data2 = new Date(timestamp * 1000).toLocaleDateString("fa-IR", options);
    } else {
      data2 = new Date(timestamp * 1000).toLocaleDateString("en-US", options);
    }
    return data2;
  };
  const { t } = useTranslation();
  return (
    <>
      <Header title={TicketMenuItemText} />
      <LeftNav />
      <div className="dashboard-panel box-panel ticket-box-panel">
        <div>
          <div>
            {preload === false ? (
              <div className="col-12 auto mt-5 tickets">
                <div>
                  <a
                    href={
                      props.Type === TypeOneValue
                        ? "/panel/ticket/add"
                        : "/panel/special-game-ticket/add"
                    }
                    className="primary-btn"
                  >
                    {t(AddTicketBtnText)}
                  </a>
                </div>
                <div className="ticket-head-table">
                  <p>{t(TicketTitlePlaceholder)}</p>
                  <p>{t(TypeOfTicketDropDownLableText)}</p>
                  <p>{t(DataHeadTable)}</p>
                  <p>{t(StateHeadTable)}</p>
                </div>

                {TheTickets.length > 0 ? (
                  TheTickets.map((Ticket) => (
                    <NavLink
                      exact
                      activeClassNNavLinkame="active-item"
                      to={"/panel/ticket/edit?id=" + Ticket.id}
                    >
                      <h2>{Ticket.subject}</h2>
                      <span>{t(Ticket.ticketType)}</span>
                      <span>{SetDate(Ticket.ticketData[0].date)}</span>
                      <span className={Ticket.ticketState}>
                        {t(Ticket.ticketState)}
                      </span>

                      {/*  <span>
                      {lang === "fa"
                        ? new Date(
                            Ticket.ticketData[0].date * 1000
                          ).toLocaleDateString("fa-IR")
                        : new Date(
                            Ticket.ticketData[0].date * 1000
                          ).toLocaleDateString()}
                    </span> */}
                    </NavLink>
                  ))
                ) : (
                  <a className="no-exist">
                    <p>{t(NotFounTicket)}</p>
                  </a>
                )}
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tickets;
