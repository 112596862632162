import React, { Component } from "react";
import "./ErrorPopUp.css";
import { useTranslation } from "react-i18next";
const ErrorPopUp = (props) => {
  const { t } = useTranslation();
  return (
    <div className="err-alert">
      <span></span>
      <h6> {t(props.ErrorText)}</h6>
    </div>
  );
};

export default ErrorPopUp;
