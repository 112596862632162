import React, { useState, useEffect, useContext } from "react";
import ReactAudioPlayer from "react-audio-player";
import AssetImg from "../AssetImg/AssetImg";
import "./UploadMusic.css";
import $ from "jquery";
//import { PlayableData } from "../EditAsset/EditAsset";
import UplaodAsset from "../EditAsset/UploadAsset/UploadAsset";
import RemoveAsset from "../EditAsset/RemoveAsset/RemoveAsset";
import {
  CreateFolderName,
  PlayableData,
} from "../DesignAppStepsComponents/DesignApp";
import ErrorPopUp from "../../../ErrorPopUp/ErrorPopUp";
import Cover from "./cover.jpeg";
import {
  chooseMusicTitle,
  ChooseMusicText,
  RemoveMusicText,
  LimitSize,
  EditThisTemplate,
  EditAssetText,
  error,
  UplaodText,
  DeleteMusicCheckBoxLabel,
  LimitFileUploadText,
} from "../../../Texts/Text";
import Loading from "../../../Tools/Loading/Loading";
import UploadCover from "./uploadcover.png";

import {
  AddAssetUrl,
  AddDefaultMusicRequest,
  BaseUrl,
  DeleteBgMusicRequest,
  StepTwoApi,
} from "../../../Texts/ApiUrl";
import { CallApi } from "../../CallApi/CallApi";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../Tools/CheckBox/CheckBox";
const UoloadMusic = (props) => {
  //const data = useContext(PlayableData);
  const MainUrl = useContext(CreateFolderName);
  const ThePlayable = useContext(PlayableData);
  const [ErrorAlert, setErrorAlert] = useState(false);
  const [errMsg, seterrMsg] = useState();
  const [GamePreviewLink, setGamePreviewLink] = useState();
  const [musicFile, setmusicFilee] = useState(null);
  const [suggestMusic, setsuggestMusic] = useState(null);
  const [preload, setpreload] = useState(true);
  const [playicon, setplayicon] = useState();
  const [DeleteMusic, setDeleteMusic] = useState(false);
  const [musicCounter, setmusicCounter] = useState(0);
  const [loadingstate, setloadingstate] = useState(false);

  useEffect(() => {
    let ListMusic = [];
    for (let i = 0; i < ThePlayable.backGroundDefaultMusic.length; i++) {
      let music = {};
      music["id"] = "music" + i;
      music["file"] = ThePlayable.backGroundDefaultMusic[i].split("***$***")[0];
      music["cover"] =
        ThePlayable.backGroundDefaultMusic[i].split("***$***")[1];
      ListMusic.push(music);
    }

    setsuggestMusic(ListMusic);
    setGamePreviewLink(
      "https://assets.wackotech.com/purchased_playable//" + MainUrl.mainFolder
    );
    setpreload(false);
  }, []);
  const ChangeMusic = async (el) => {
    setErrorAlert(false);
    let limitSize = el.target.files[0].size / 1024;
    if (limitSize < 2048) {
      let elements = document.getElementsByClassName("music-cover");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
      var selectedEl = document.getElementById("MusicUploader");
      selectedEl.style.display = "block";
      await ReplaceAsset(el.target.files[0]);
    } else {
      seterrMsg(LimitSize);
      setErrorAlert(true);
    }
  };
  const DeletMusic = async (FolderPath) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      assetWithFormat: "Background-Music.mp3",
      directoryName: "/" + FolderPath.mainFolder + FolderPath.assetFolder,
    });
    console.log(raw);
    const result = await CallApi(
      BaseUrl + DeleteBgMusicRequest,
      raw,
      myHeaders,
      "POST"
    );
    if (result[1] === 200) {
      let elements = document.getElementsByClassName("music-cover");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
        //elements[i].childNodes[0].text = EditThisTemplate;
      }
    }
  };
  const ReplaceAsset = async (File) => {
    setloadingstate(true);
    await setGamePreviewLink("");
    let data =
      '{"name":"' +
      "Background-Music" +
      '","folderPath":"' +
      "/" +
      MainUrl.mainFolder +
      MainUrl.assetFolder +
      '"}';
    console.log(data);
    var myHeaders = new Headers();

    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );

    var formdata = new FormData();
    formdata.append("data", data);
    formdata.append("file", File);

    const result = await CallApi(
      BaseUrl + StepTwoApi,
      formdata,
      myHeaders,
      "POST"
    );
    console.log(result);
    //console.log(formdata);
    if (result[1] === 200) {
      //await setGamePreviewLink("");
      setDeleteMusic(false);
      let file = await URL.createObjectURL(File);
      setmusicFilee(file);

      await setGamePreviewLink(result[0].data);
      setloadingstate(false);
      console.log(result[0].data);
    } else {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(error);
    }
  };
  const AddDefaultMusic = async (MusicUrl, elId) => {
    setErrorAlert(false);
    let elements = document.getElementsByClassName("music-cover");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
      //elements[i].childNodes[0].text = EditThisTemplate;
    }
    var selectedEl = document.getElementById(elId);
    selectedEl.style.display = "block";
    setloadingstate(true);
    setGamePreviewLink(null);
    setmusicCounter(musicCounter + 1);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    let format = MusicUrl.split(/[#?]/)[0].split(".").pop().trim();
    var raw = JSON.stringify({
      folderDestination: "/" + MainUrl.mainFolder + MainUrl.assetFolder,
      nameWithFormat: "Background-Music.mp3",
      url: MusicUrl,
    });
    console.log(raw);
    /*  console.log(localStorage.getItem("UserToken"));
    console.log(raw);
    console.log(BaseUrl + AddDefaultMusicRequest); */
    myHeaders.append("Content-Type", "application/json");
    //console.log(raw);
    const result = await CallApi(
      BaseUrl + AddDefaultMusicRequest,
      raw,
      myHeaders,
      "POST"
    );
    console.log(result);
    if (result[1] === 200) {
      setloadingstate(false);
      setDeleteMusic(false);
      setGamePreviewLink(
        "https://assets.wackotech.com/purchased_playable//" + MainUrl.mainFolder
      );
      setmusicFilee(MusicUrl);
    } else if (result[1] === 403) {
      window.location = "/login";
    } else {
      seterrMsg(error);
      setErrorAlert(true);
      setloadingstate(false);
    }
  };
  const CheckDeleteMusic = async (el) => {
    await setGamePreviewLink("");
    setDeleteMusic(true);
    await DeletMusic(MainUrl);
    setGamePreviewLink(
      "https://assets.wackotech.com/purchased_playable//" + MainUrl.mainFolder
    );
  };

  const { t } = useTranslation();
  return (
    <>
      <>
        {ThePlayable.landScape === true ? (
          <AssetImg
            LandScape={ThePlayable.landScape}
            src={GamePreviewLink}
            EditScroll={true}
            EditSctollText={t("Edit_Music")}
          />
        ) : null}
        <div
          className={
            ThePlayable.landScape === true
              ? "col-10 auto choose-music pt-3 pb-5"
              : "col-lg-9 col-md-6 choose-music pt-3 pb-5"
          }
        >
          {/*     <h3>{t(chooseMusicTitle)}</h3> */}
          <div className="edit-asset-item">
            {preload === true ? (
              <Loading />
            ) : (
              suggestMusic.map((music) => (
                <div>
                  <img src={music.cover} />
                  <div id={music.id} className="music-cover">
                    <div className="edit-img-profile">
                      <p
                        className="play-btn"
                        onClick={(e) => AddDefaultMusic(music.file, music.id)}
                      >
                        {t(EditThisTemplate)}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
            <div>
              <img src={UploadCover} />
              <div id="MusicUploader" className="music-cover">
                <UplaodAsset
                  fonticon={<i className="fa fa-upload" aria-hidden="true"></i>}
                  Change={(e) => ChangeMusic(e)}
                  Text={t(UplaodText)}
                />
                <h6>{t(LimitFileUploadText)}</h6>
              </div>
            </div>
          </div>

          <ReactAudioPlayer
            className="mt-1 auto music-player"
            src={musicFile === null ? null : musicFile}
            controls
          />
          <div>
            <CheckBox
              chacked={DeleteMusic === true ? true : false}
              Lable={DeleteMusicCheckBoxLabel}
              onChange={(e) => CheckDeleteMusic(e)}
            />
          </div>
        </div>
        {ThePlayable.landScape === false ? (
          <AssetImg LandScape={ThePlayable.landScape} src={GamePreviewLink} />
        ) : null}
        {ErrorAlert === true ? <ErrorPopUp ErrorText={errMsg} /> : null}
        {loadingstate === true ? <Loading /> : null}{" "}
      </>
      {/*  <div className="col-12 fixed-nav-bottom pt-2">
      <div className="redirect-btn design-playable mt-0">
        {props.LastPage}
        {props.NextStep}
      </div>
      </div> */}
      <div
        className={
          ThePlayable.landScape === false
            ? "col-12 fixed-nav-bottom pt-2"
            : "col-12 pt-2"
        }
      >
        <div className="redirect-btn mb-0 ">
          {props.LastPage}
          {props.NextStep}
        </div>
      </div>
    </>
  );
};

export default UoloadMusic;
