import React, { Component } from "react";

import "./UploadAsset.css";
class UploadAsset extends Component {
  render() {
    return (
      <div className="edit-img-profile mt-1">
        <input
          id={this.props.id}
          type="file"
          className="custom-file-input"
          onChange={this.props.Change}
          multiple
          accept={this.props.accept}
        />
        <p>
          {this.props.fonticon} {this.props.Text}
        </p>
      </div>
    );
  }
}

export default UploadAsset;
