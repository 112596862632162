import React from "react";
import gif from "./loading2.gif";
import "./Loading.css";

const Loading2 = () => {
  return (
    <div className="loading-mini">
      <img src={gif} alt="loading" />
    </div>
  );
};

export default Loading2;
