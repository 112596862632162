import React, { useEffect, useState, useContext } from "react";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import { PlayableStatisticData } from "../ProfileBody/ProfileBody";
import {
  clickDownloadFromStoreCountText,
  GamePlayCount,
  insertRecordText,
  PieChartTitle,
  shareCountText,
  StatisticsGamesTitleText,
  ViewText,
} from "../../../Texts/Text";
import Loading from "../../../Tools/Loading/Loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ChangeLang } from "../../../Redux/lang/LangAction";
const ChartsSection = () => {
  const [ChartData, setChartData] = useState();
  const [PieChartData, setPieChartData] = useState();
  const [preload, setpreload] = useState(true);
  const StatisticData = useContext(PlayableStatisticData);
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";


  useEffect(() => {
    let bgcolor = [
      "rgb(169,220,218)",
      "rgb(255,182,201)",
      "rgb(153,211,242)",
      "rgb(208,141,141)",
      "rgb(126,126,126)",
      "rgb(128,131,205)",
      "rgb(200,207,128)",
      "rgb(11,175,241)",
      "rgb(241,205,179)",
      "rgb(169,171,171)",
      "rgb(203,175,241)",
      "rgb(255,101,80)",
      "rgb(147, 66, 129)",
      "rgb(168, 199, 38)",
      "rgb(102, 210, 164)",
    ];
    let bordercolor = [
      "rgb(169,220,218)",
      "rgb(255,182,201)",
      "rgb(153,211,242)",
      "rgb(208,141,141)",
      "rgb(126,126,126)",
      "rgb(128,131,205)",
      "rgb(200,207,128)",
      "rgb(11,175,241)",
      "rgb(241,205,179)",
      "rgb(169,171,171)",
      "rgb(203,175,241)",
      "rgb(255,101,80)",
      "rgb(147, 66, 129)",
      "rgb(168, 199, 38)",
      "rgb(102, 210, 164)",
    ];
    const LineChartData = async () => {
      var LableList = [];

      let Allstatistics = [];
      for (let i = 0; i < StatisticData.length; i++) {
        let statistic = {};

        if (StatisticData[i].statisticList != null) {
          LableList.push(t(StatisticData[i].gameName));
          statistic["label"] = t(StatisticData[i].gameName);
          statistic["fill"] = true;
          statistic["lineTension"] = 0.3;
          statistic["showLine"] = true;
          statistic["spanGaps"] = true;
          statistic["backgroundColor"] = bgcolor[i];
          statistic["borderColor"] = bordercolor[i];
          statistic["borderWidth"] = 0;
          statistic["data"] = [
            StatisticData[i].statisticList[0].playCount,
            StatisticData[i].statisticList[0].addUserNameCount,
            StatisticData[i].statisticList[0].clickDownloadFromStoreCount,
            StatisticData[i].statisticList[0].shareCount,
            StatisticData[i].statisticList[0].pageViewCount,
          ];
          Allstatistics.push(statistic);
        }
      }

      LableList = Object.values(LableList);
      //console.log(LableList);
      const LineChartData = {
        labels: [
          t(GamePlayCount),
          t(insertRecordText),
          t(clickDownloadFromStoreCountText),
          t(shareCountText),
          t(ViewText),
        ],
        datasets: Allstatistics,
      };
      setChartData(LineChartData);
    };
    const PieChart = async () => {
      var LableList = [];
      let statisticbg = [];
      let ViewCount = [];
      for (let i = 0; i < StatisticData.length; i++) {
        if (StatisticData[i].statisticList !== null) {
          LableList.push(t(StatisticData[i].gameName));
          statisticbg.push(bordercolor[i]);
          ViewCount.push(StatisticData[i].statisticList[0].pageViewCount);
        }
      }
      const PieChartData = {
        labels: Object.values(LableList),
        datasets: [
          {
            borderColor: "white",
            borderWidth: "0",
            backgroundColor: Object.values(statisticbg),
            hoverBorderColor: "white",
            data: Object.values(ViewCount),
          },
        ],
      };
      setPieChartData(PieChartData);
    };
    LineChartData();
    PieChart();
    setpreload(false);
  }, [lang]);
  const { t } = useTranslation();
  return (
    <section className="ps-0">
      {preload === true ? (
        <Loading />
      ) : (
        <div className="container-fluid p-0">
          <div className="row">
            <PieChart data={PieChartData} title={t(PieChartTitle)} />
            <LineChart data={ChartData} title={t(StatisticsGamesTitleText)} />
          </div>
        </div>
      )}
    </section>
  );
};

export default ChartsSection;
