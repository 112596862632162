import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseUrl,
  GetFactorWithDirectoryNameReq,
  GetPlayableapi,
  GetStatisticDataRequest,
} from "../../../Texts/ApiUrl";
import {
  clickDownloadFromStoreCountText,
  GameDetailTitlePage,
  GamePlayCount,
  GetGameLInk,
  GetSourceCodeText,
  insertRecordText,
  shareCountText,
  ViewText,
} from "../../../Texts/Text";
import Loading from "../../../Tools/Loading/Loading";
import { CallApi } from "../../CallApi/CallApi";
import LeftNav from "../../LeftNav/LeftNav";
import LineChart from "../Charts/LineChart";
import PieChart from "../Charts/PieChart";
import Header from "../ProfleHeader/ProfileHeader";
import "./GameDetail.css";
const GameDetail = (props) => {
  const search = window.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  var playableid = params.get("playableid");
  var url = params.get("url");
  const [preLoad, setpreLoad] = useState(true);
  const [PieChartData, setPieChartData] = useState();
  const [ChartData, setChartData] = useState();
  const [haveStatistic, sethaveStatistic] = useState(true);
  const [Playable, setplayable] = useState();
  const [thefactor, setthefactor] = useState();
  const GetStatistic = async () => {
    let thePlayable = await GetPlayable();
    console.log(thePlayable);
    setplayable(thePlayable);
    let theStatistic = await GetTheStatistic(
      thePlayable.id,
      thePlayable.packageName
    );
    return theStatistic;
  };
  const Getfactor = async (name) => {
    var Url = BaseUrl + GetFactorWithDirectoryNameReq + "?name=" + name;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});

    const result = await CallApi(Url, raw, myHeaders, "GET");
    //console.log(result);
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    }
  };
  useEffect(() => {
    const GetStatisticData = async () => {
      let factor = await Getfactor(url.split("/")[4]);
      setthefactor(factor);
      var StatisticData = await GetStatistic();

      let bgcolor = [
        "rgba(75, 192, 192, 0.322)",
        "rgba(255, 99, 133, 0.37)",
        "rgba(54, 162, 235, 0.37)",
        "rgba(219, 99, 255, 0.37)",
        "rgba(255, 226, 99, 0.37)",
        "rgba(173, 47, 25, 0.37)",
      ];
      let bordercolor = [
        "rgba(75, 192, 192, 0.69)",
        "rgba(255, 99, 132, 0.72)",
        "rgba(54, 162, 235, 0.69)",
        "rgba(219, 99, 255, 0.69)",
        "rgba(255, 226, 99, 0.69)",
        "rgba(173, 47, 25, 0.69)",
      ];
      const LineChartData = async () => {
        var LableList = [];

        let Allstatistics = [];
        //console.log(StatisticData);
        let statistic = {};
        if (StatisticData.statistic !== null) {
          LableList.push(StatisticData.statistic.assetIdentifier);
          statistic["label"] = t(StatisticData.gameName);
          statistic["fill"] = true;
          statistic["lineTension"] = 0.3;
          statistic["showLine"] = true;
          statistic["spanGaps"] = true;
          statistic["backgroundColor"] = bgcolor[0];
          statistic["borderColor"] = bordercolor[0];
          statistic["borderWidth"] = 2;
          statistic["data"] = [
            StatisticData.statistic.playCount,
            StatisticData.statistic.addUserNameCount,
            StatisticData.statistic.clickDownloadFromStoreCount,
            StatisticData.statistic.shareCount,
            StatisticData.statistic.pageViewCount,
          ];
          Allstatistics.push(statistic);
        }

        LableList = Object.values(LableList);
        //console.log(LableList);
        const LineChartData = {
          labels: [
            t(GamePlayCount),
            t(insertRecordText),
            t(clickDownloadFromStoreCountText),
            t(shareCountText),
            t(ViewText),
          ],
          datasets: Allstatistics,
        };
        setChartData(LineChartData);
      };
      const PieChart = async () => {
        var LableList = [
          t(ViewText),
          t(GamePlayCount),

          t(clickDownloadFromStoreCountText),
        ];
        let statisticbg = [];
        let ViewCount = [];
        for (let i = 0; i < LableList.length; i++) {
          if (StatisticData !== null) {
            statisticbg.push(bordercolor[i]);
          }
        }
        if (StatisticData.statistic != null) {
          ViewCount.push(StatisticData.statistic.pageViewCount);
          ViewCount.push(StatisticData.statistic.playCount);
          /*  ViewCount.push(StatisticData.statistic.addUserNameCount); */
          ViewCount.push(StatisticData.statistic.clickDownloadFromStoreCount);
          /*    ViewCount.push(StatisticData.statistic.shareCount); */
          const PieChartData = {
            labels: Object.values(LableList),
            datasets: [
              {
                borderColor: "white",
                borderWidth: "4",
                backgroundColor: Object.values(statisticbg),
                hoverBorderColor: "white",
                data: Object.values(ViewCount),
              },
            ],
          };
          setPieChartData(PieChartData);
        }
      };
      if (StatisticData !== null) {
        PieChart();
        LineChartData();
      } else {
        sethaveStatistic(false);
      }

      setpreLoad(false);
    };
    GetStatisticData();
  }, []);
  const GetPlayable = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(
      BaseUrl + GetPlayableapi + "?id=" + playableid,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    } else {
      return {
        assets: [],
      };
    }
  };
  const GetTheStatistic = async (PlayableId, PackageName) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    const result = await CallApi(
      BaseUrl +
        GetStatisticDataRequest +
        "?packageName=" +
        PackageName +
        "&assetIdentifier=" +
        PackageName +
        "&playableReferenceId=" +
        PlayableId,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {preLoad === true ? (
        <Loading />
      ) : (
        <>
          <Header title={GameDetailTitlePage} />
          <LeftNav />
          <div className="dashboard-panel mt-2">
            <div>
              <section className="mt-5 ps-0">
                {haveStatistic === false ? null : (
                  <div className="container-fluid p-0">
                    <div className="row">
                      <PieChart data={PieChartData} />
                      <LineChart data={ChartData} />
                    </div>

                    <div className="row mt-3 ">
                      <div className="col-12">
                        <div className="pl-5 redirect-btn mb-2">
                          <a href={url} className="primary-btn" target="_blank">
                            {t(GetGameLInk)}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 mb-3 center-text">
                      <a
                        target="_blank"
                        href={
                          "/panel/Ticket/add?template=GetSource&url=" +
                          url +
                          "&PlayableName=" +
                          Playable.name +
                          "&FactorId=" +
                          thefactor.id
                        }
                      >
                        <i className="fa fa-comment" aria-hidden="true"></i>
                        {t(GetSourceCodeText)}
                      </a>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GameDetail;
