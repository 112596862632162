import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import Header from "../ProfileComponents/ProfleHeader/ProfileHeader";
import LeftNav from "../LeftNav/LeftNav";
import Body from "../ProfileComponents/ProfileBody/ProfileBody";
import Footer from "../PanelFooter/PanelFooter";
import { DashboardMenuItemText } from "../../Texts/Text";
import { BaseUrl, ProfilrInfo } from "../../Texts/ApiUrl";
import { CallApi } from "../CallApi/CallApi";
import Loading from "../../Tools/Loading/Loading";
import i18next from "i18next";
import { ChangeLang } from "../../Redux/lang/LangAction";
import { stat } from "fs";
const Dashboard = () => {
  const [preLoad, setpreLoad] = useState(true);
  const language = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  useEffect(() => {
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    // bar
    var lang = params.get("lang");

    /* console.log(lang); */
    if (lang !== null) {
      i18next.changeLanguage(lang);
      dispatch(ChangeLang(lang));
    }
    const GetImportantData = async () => {
      let UserData = await GetProfile();
      console.log(UserData);
      setpreLoad(false);
    };
    GetImportantData();
  }, []);
  const GetProfile = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");

    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };

  return (
    <>
      {preLoad === true ? (
        <Loading />
      ) : (
        <>
          <Header title={DashboardMenuItemText} />
          <LeftNav />
          <div className="dashboard-panel mt-2">
            <Body />
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
