import React from "react";
import { DesignAppMenuItemText } from "../../../Texts/Text";
import LeftNav from "../../LeftNav/LeftNav";
import Section from "../../PanelSection/Section";
import Header from "../../ProfileComponents/ProfleHeader/ProfileHeader";
import Export from "../Export/Export";
import TimeLine from "../TimeLine/TimeLine";
import TimeLineWithOutMusic from "../TimeLine/TimeLineWithOutMusic";

const DesignExport = (props) => {
  return (
    <>
      <Header title={DesignAppMenuItemText} />
      <LeftNav />
      <div className="dashboard-panel box-panel">
        <div>
          <div>
            {props.havemusic === true ? (
              <TimeLine PageNum="6" />
            ) : (
              <TimeLineWithOutMusic PageNum="6" />
            )}

            <Section
              styles="ps-0 mt-5 site-direction"
              ChildElement={<Export />}
            />
          </div>
        </div>
        {/*  <p className="pt-3"></p> */}
      </div>
    </>
  );
};

export default DesignExport;
