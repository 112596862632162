import React from "react";
import "./ImageUpload.css";
const ImageUpload = (props) => {
  return (
    <div>
      <div className="avatar">
        <img id={props.ImageId} src={props.src} />
        <div>
          <input id="FileUpload" type="file" onChange={props.onChange} />
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
