import React, { useContext, useEffect, useState } from "react";
import "./NumStatistics.css";
import { PlayableStatisticData } from "../ProfileBody/ProfileBody";
import Cookies from "js-cookie";
import {
  addUserNameCountText,
  clickDownloadFromStoreCountText,
  shareCountText,
} from "../../../Texts/Text";
import Loading from "../../../Tools/Loading/Loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NumericalStatisticsBox = () => {
  const StatisticData = useContext(PlayableStatisticData);
  const [Statistics, setStatistics] = useState();
  /* const [lang, setlang] = useState(); */
  const [preload, setpreload] = useState(true);
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    useEffect(() => {
    const theCookie = Cookies.get("i18next");
    /*  const theCookie = Cookies.get("i18next");
    setlang(theCookie); */
    /* if (theCookie === "en") {
      var BannerInfo = document.querySelector(".banner-info");
      BannerInfo.classList.add("banner-info-en");
    } */
    const SetStatisticData = async () => {
      let statistic = {};
      statistic["downlaodCount"] = 0;
      statistic["shareCount"] = 0;
      statistic["insertRecord"] = 0;
      console.log(StatisticData);
      for (let i = 0; i < StatisticData.length; i++) {
        if (StatisticData[i].statisticList !== null) {
          statistic["downlaodCount"] =
            statistic["downlaodCount"] +
            StatisticData[i].statisticList[0].clickDownloadFromStoreCount;

          statistic["shareCount"] =
            statistic["shareCount"] +
            StatisticData[i].statisticList[0].shareCount;

          statistic["insertRecord"] =
            statistic["insertRecord"] +
            statistic["shareCount"] +
            StatisticData[i].statisticList[0].addUserNameCount;
        }
      }
      if (theCookie === "fa") {
        statistic["downlaodCount"] = e2p(statistic["downlaodCount"]);
        statistic["insertRecord"] = e2p(statistic["insertRecord"]);
        statistic["shareCount"] = e2p(statistic["shareCount"]);
      }
      //console.log(statistic);
      await setStatistics(statistic);
      setpreload(false);
    };
    SetStatisticData();
  }, [lang]);
  const e2p = (s) => s.toString().replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[d]);

  const { t } = useTranslation();
  return (
    <section className="mt-5 ps-0">
      {preload === true ? (
        <Loading />
      ) : (
        <div className="container-fluid p-0">
          <div className="row">
            <div
              className={
                lang === "fa"
                  ? "col-md-4 banner-info p-2"
                  : "col-md-4 banner-info banner-info-en p-2"
              }
            >
              <div>
                <h2>
                  {" "}
                  <i className="fa fa-share-alt" aria-hidden="true"></i>
                  {t(shareCountText)}
                </h2>
                <span>{Statistics.shareCount}</span>
              </div>
            </div>
            <div
              className={
                lang === "fa"
                  ? "col-md-4 banner-info p-2"
                  : "col-md-4 banner-info banner-info-en p-2"
              }
            >
              <div>
                <h2>
                  <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                  {t(addUserNameCountText)}
                </h2>
                <span>{Statistics.insertRecord}</span>
              </div>
            </div>
            <div
              className={
                lang === "fa"
                  ? "col-md-4 banner-info p-2"
                  : "col-md-4 banner-info banner-info-en p-2"
              }
            >
              <div>
                <h2>
                  <i className="fa fa-download" aria-hidden="true"></i>
                  {t(clickDownloadFromStoreCountText)}
                </h2>
                <span>
                  {lang === "fa"
                    ? Statistics.downlaodCount
                    : Statistics.downlaodCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default NumericalStatisticsBox;
