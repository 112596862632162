import { CallApi } from "../../PanelComponent/CallApi/CallApi";
import { BaseUrl, GetAllPlayable } from "../../Texts/ApiUrl";

export const SendRequest = () => {
  return {
    type: "REQUEST",
  };
};
export const Success = (Playbles) => {
  return {
    type: "SUCCESS",
    payload: Playbles,
  };
};
export const getPlayable = () => {
  return (dispath) => {
    dispath(SendRequest());
    LoadTemplate();
    const LoadTemplate = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("UserToken"));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({});

      const result = await CallApi(
        BaseUrl + GetAllPlayable,
        raw,
        myHeaders,
        "GET"
      );
      if (result[1] === 200) {
        dispath(Success(result[0].data));
      }
    };
  };
};
