import React, { useState, useEffect } from "react";
import $ from "jquery";
import "../TemplatesCard/TemplatesCard.css";
import {
  FilterItem1,
  FilterItem2,
  FilterItem3,
  FilterItem4,
  FilterItem5,
  NextPage,
  EditThisTemplate,
  ThisTemplateSelected,
  AllContent,
  ChooseGameText,
} from "../../../Texts/Text";
import { CallApi } from "../../CallApi/CallApi";
import {
  CryptoGame,
  Running,
  Pazzel,
  Hunting,
  Application,
} from "../../../Texts/Category";
import { GetAllPlayable, BaseUrl } from "../../../Texts/ApiUrl";
import WOW from "wowjs";
import Loading from "../../../Tools/Loading/Loading";
import Fade from "react-reveal/Fade";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Title from "../../../Images/loading6.png";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import {
  getPlayable,
  SendRequest,
  Success,
} from "../../../Redux/playable/PlayableAction";
import { useDispatch } from "react-redux";
//import TradeImage from "./trader.png";
const TemplatesCard = () => {
  const [playables, setplayables] = useState([]);
  const [SelectId, setSelectId] = useState(null);
  const [Category, setCategory] = useState(null);
  const [FilterData, setFilterData] = useState([]);
  const [preLoad, setpreLoad] = useState(false);
  const [FirstLoad, setFirstLoad] = useState(0);
  /* const [lang, setlang] = useState(Cookies.get("i18next")); */
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    const Palyable = useSelector((state) => state.PlayablesState);
  const dispatch = useDispatch();
  useEffect(() => {
    setplayables(Palyable.Data);
    const GetData = async () => {
      setpreLoad(true);
      if (Palyable.DataExist === false) {
        dispatch(SendRequest());
        let data = await LoadTemplate();
        setFilterData(data);
        setplayables(data);
        /* setFirstLoad(1); */
      } else {
        setFilterData(Palyable.Data);
        setplayables(Palyable.Data);
        if (Category === null) {
          //setpreLoad(true);
          setFilterData(Palyable.Data);
        } else {
          let data = await FilterPlayabl(Category);
          setFilterData(data);
        }
      }

      setpreLoad(false);
    };
    GetData();
  }, [Category]);

  const componentDidMount = () => {
    new WOW.WOW({
      live: false,
    }).init();

    $(".filter-template > li").click(function () {
      $(".filter-template > li").removeClass("filter-template-active");
      $(this).addClass("filter-template-active");
    });
  };
  const CryptoGameItems = async () => {
    setCategory(CryptoGame);
  };
  const RunningItems = async () => {
    //(true);
    //setpreLoad(true);
    setCategory(Running);
    //let data = await FilterPlayabl(Running);
    //setFilterData(data);
    //var data = LoadTemplate({ Running });
    //setplayables(data);
  };
  const PazzelItems = async () => {
    //let data = await FilterPlayabl(Pazzel);
    //setpreLoad(true);
    setCategory(Pazzel);
    //setFilterData(data);
    //var data = LoadTemplate({ Pazzel });
    //setplayables(data);
  };
  const GetAll = async () => {
    Category === null ? setpreLoad(false) : setpreLoad(true);
    setCategory(null);
    //setFilterData(playables);
  };
  const LoadTemplate = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("UserToken"));
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    const result = await CallApi(
      BaseUrl + GetAllPlayable,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      dispatch(Success(result[0].data));
      return result[0].data;
    }
  };
  const Select = (playableid, elId) => {
    let elements = document.getElementsByClassName("template-item");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.opacity = "0";
      elements[i].childNodes[0].text = EditThisTemplate;
    }
    var selectedEl = document.getElementById(elId);
    selectedEl.style.opacity = "1";
    selectedEl.childNodes[0].text = ThisTemplateSelected;
    setSelectId(playableid);
  };
  const FilterPlayabl = async (Category) => {
    let Data = playables.filter((a) => a.category.name === Category);

    return Data;
  };
  const ChangeFilterTab = async (CategoryName, SelectId) => {
    var element = document.getElementsByClassName("filter-template");
    for (let i = 0; i < element[0].childNodes.length; i++) {
      element[0].childNodes[i].classList.remove("filter-template-active");
    }
    console.log(SelectId);
    var Selectelement = document.getElementById(SelectId);
    Selectelement.classList.add("filter-template-active");
    setCategory(CategoryName);
  };
  const { t } = useTranslation();
  return (
    <>
      {componentDidMount()}

      <div className="col-12 mt-5 site-direction">
        <ul className="filter-template">
          <li onClick={GetAll} className="filter-template-active">
            {t(AllContent)}
          </li>
          <li id="category3" onClick={CryptoGameItems}>
            {t(FilterItem1)}
          </li>
          <li id="category2" onClick={RunningItems}>
            {t(FilterItem2)}
          </li>
          <li id="category1" onClick={PazzelItems}>
            {t(FilterItem3)}
          </li>
        </ul>
      </div>
      <div className="row mt-3 plr-6 mb-5 site-direction">
        {preLoad === false ? (
          FilterData.map((playable) => (
            <Fade bottom>
              <div className="col-xl-3 col-lg-4 col-md-6 template-card pl-3 pr-3 pb-0 pt-3 mt-0">
                <div>
                  <LazyLoadImage
                    placeholderSrc={Title}
                    effect="blur"
                    placeholder={<Loading />}
                    src={
                      lang === "fa"
                        ? playable.previewFileUrl.split("***$***")[0]
                        : playable.previewFileUrl.split("***$***")[1]
                    }
                  />
                  <div id={playable.name} className="template-item">
                    <a
                      href={
                        "/panel/designapp/customize-playable?id=" + playable.id
                      }
                    >
                      {t(ChooseGameText)}
                    </a>
                  </div>
                </div>
                <h3>{t(playable.name)}</h3>
                <span
                  onClick={(e) =>
                    ChangeFilterTab(
                      playable.category.name,
                      "category" + playable.category.id
                    )
                  }
                >
                  {t(playable.category.name)}
                </span>
              </div>
            </Fade>
          ))
        ) : (
          <Loading />
        )}
      </div>
      <div className="row">
        {SelectId !== null ? (
          <a
            href={"/panel/DesignApp/CustomizePlayable?id=" + SelectId}
            className="green-btn mb-5"
          >
            {NextPage}
          </a>
        ) : null}
      </div>
    </>
  );
};

export default TemplatesCard;
