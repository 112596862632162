import React, { useState, useEffect } from "react";
import ReplaceAsset from "../DesignAppStepsComponents/ReplaceAsset";
import ChooseMusic from "./ChooseMusic";
import Payment from "./Payment";
import { CallApi } from "../../CallApi/CallApi";
import {
  BaseUrl,
  GetPlayableapi,
  ProfilrInfo,
  AddUserPlayableApi,
  AddAssetUrl,
  StepOneApi,
  PayPingPayRequest,
  DeleteBgMusicRequest,
  ChangeCallToActionRequest,
} from "../../../Texts/ApiUrl";
import Loading from "../../../Tools/Loading/Loading";
import ErrorPopUp from "../../../ErrorPopUp/ErrorPopUp";
import DesignExport from "./DesignExport";
import ChooseLink from "./ChooseLink";
import {
  NextPage,
  error,
  LargesImageInAddAsset,
  ErrorAddAsset,
  PayBtnText,
  LastPage,
  RemoveMusicText,
  DeleteMusicCheckBoxLabel,
} from "../../../Texts/Text";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../Tools/CheckBox/CheckBox";
export const PlayableData = React.createContext();
export const CreateFolderName = React.createContext();
const DesignApp = () => {
  const [PageNum, setPageNum] = useState(2);
  const [SendAssets, setSendAssets] = useState();
  const [errMsg, seterrMsg] = useState();
  const [ErrorAlert, setErrorAlert] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [playable, setplayable] = useState();
  const [TheUser, setTheUser] = useState();
  const [LoadPlayable, setLoadPlayable] = useState(true);
  const [mainurl, setmainurl] = useState(null);
  const [havemusic, sethavemusic] = useState();

  const [DeleteMusic, setDeleteMusic] = useState(false);
  const search = window.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  var playableid = params.get("id"); // bar
  var clientrefId = params.get("clientRefId"); // bar
  useEffect(() => {
    console.log(localStorage.getItem("UserToken"));
    const SetImportandData = async () => {
      //console.log(mainurl);
      if (clientrefId !== null) {
        setPageNum(6);
        localStorage.setItem("clientrefid", clientrefId);
        setmainurl(localStorage.getItem("path"));

        playableid = localStorage.getItem("thePlayableId");
        let ThePlayable = await GetPlayable();

        setplayable(ThePlayable);
        let UserData = await GetUser();
        setTheUser(UserData);
        setLoadPlayable(false);
      } else {
        let ThePlayable = await GetPlayable();
        /*   console.log(ThePlayable); */
        setplayable(ThePlayable);
        if (ThePlayable.haveBackGroundMusic === true) {
          sethavemusic(true);
        } else {
          sethavemusic(false);
        }
        let UserData = await GetUser();
        setTheUser(UserData);
        if (mainurl === null) {
          await CreateFolder(ThePlayable, UserData);
        }

        setLoadPlayable(false);
      }
    };
    SetImportandData();
  }, []);
  const RedirecttoChooseMusic = async () => {
    await saveData();
    if (playable.haveBackGroundMusic === true) {
      setPageNum(3);
    } else {
      setPageNum(4);
    }
  };
  const CreateFolder = async (_playable, _user) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      description: _playable.name,
      email: _user.email,
      fullName: _user.email.split("@")[0],
      playableId: playableid,
      uuid: _user.uuid,
    });
    console.log(raw);
    console.log(localStorage.getItem("UserToken"));
    console.log(BaseUrl + StepOneApi);
    const result = await CallApi(BaseUrl + StepOneApi, raw, myHeaders, "POST");

    if (result[1] === 200) {
      console.log(result[0].data);
      setmainurl(result[0].data);
    }
  };
  const DeletMusic = async (FolderPath) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("UserToken")
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      assetWithFormat: "Background-Music.mp3",
      directoryName: "/" + FolderPath.mainFolder + FolderPath.assetFolder,
    });
    console.log(raw);
    const result = await CallApi(
      BaseUrl + DeleteBgMusicRequest,
      raw,
      myHeaders,
      "POST"
    );
    if (result[1] === 200) {
    }
  };
  const saveData = async () => {
    let Assets = [];
    let elements = document.getElementsByClassName("custom-file-input");

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].files.length > 0) {
        let Asset = {};
        Asset["DataFile"] = elements[i].files[0];
        Asset["DataName"] = elements[i].id;
        Assets.push(Asset);
      }
    }

    await setSendAssets(Assets);
  };
  const GoChangeLink = async () => {
    await SaveMusic();
    setPageNum(4);
  };
  const ChangeLink = async () => {
    const Link = document.getElementById("SpecialLink").value;
    console.log(Link);
    if (Link != null || Link !== "") {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("UserToken"));
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        callToActionUri: Link,
        developType: playable.gameDevelopType,
        folderName: mainurl.mainFolder,
      });
      let Url = BaseUrl + ChangeCallToActionRequest;
      const result = await CallApi(Url, raw, myHeaders, "POST");
      if (result[1] === 200) {
        let data = result[0].data;
        return data;
      } else {
        return;
      }
    } else {
      alert("khali");
    }
  };
  const Pay = async () => {
    await ChangeLink();
    setPageNum(5);
  };
  const SaveMusic = async () => {
    let Assets = SendAssets;
    let elements = document.getElementsByClassName("custom-file-input");

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].files.length > 0) {
        let Asset = {};
        Asset["DataFile"] = elements[i].files[0];
        Asset["DataName"] = "BackgroundMusic";
        Assets.push(Asset);
      }
    }

    await setSendAssets(Assets);

    /* await DeletMusic(mainurl); */
  };
  const AddAsset = async () => {
    var Assets = [];

    for (var i = 0; i < SendAssets.length; i++) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("UserToken"));

      var formdata = new FormData();
      formdata.append("file", SendAssets[i].DataFile);
      formdata.append("data", '{"name":"' + SendAssets[i].DataName + '"}');

      const result = await CallApi(
        BaseUrl + AddAssetUrl,
        formdata,
        myHeaders,
        "POST"
      );

      if (result[1] === 200) {
        setErrorAlert(false);
        //setloadingstate(false);
        result[0].data.assets.fileUrl =
          result[0].data.baseAssetPath + result[0].data.assets.fileUrl;
        let Asset = {};
        Asset[result[0].data.assets.name] = result[0].data.assets;
        Assets.push(Asset);
      } else if (result[1] === 403) {
        setErrorAlert(false);
        setloadingstate(false);
        window.location = "/panel/login";
      } else if (result[1] === 413) {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(LargesImageInAddAsset);
      } else {
        setloadingstate(false);
        setErrorAlert(true);
        seterrMsg(ErrorAddAsset);
      }
    }
    //console.log(Assets);
    //console.log(Assets);

    return Assets;
  };
  const AddUserPlayable = async () => {
    setloadingstate(true);
    localStorage.setItem("path", mainurl.mainFolder);
    localStorage.setItem("thePlayableId", playableid);
    // var PlayableAssets = [];
    //PlayableAssets = await AddAsset();
    //console.log(Object.values(PlayableAssets));
    //const Assetsobj = Object.fromEntries(PlayableAssets);
    //const allAssets = Object.assign({}, ...PlayableAssets);
    //console.log(allAssets);
    //var myHeaders = new Headers();
    //myHeaders.append("Authorization", localStorage.getItem("UserToken"));
    //myHeaders.append("Content-Type", "application/json");
    //console.log(raw);
    //var raw = JSON.stringify({});
    let qyery =
      "?description=test&payerIdentity=" +
      TheUser.email +
      "&directoryName=" +
      mainurl.mainFolder +
      "&payerName=" +
      TheUser.email.split("@")[0] +
      "&playableId=" +
      playableid +
      "&uuid=" +
      TheUser.uuid;
    //console.log(BaseUrl + PayPingPayRequest + qyery);
    window.location = BaseUrl + PayPingPayRequest + qyery;
  };
  const GetPlayable = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});
    const result = await CallApi(
      BaseUrl + GetPlayableapi + "?id=" + playableid,
      raw,
      myHeaders,
      "GET"
    );
    if (result[1] === 200) {
      return result[0].data;
    } else {
      return {
        assets: [],
      };
    }
  };
  const GetUser = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };
  const GoDessignApp = async () => {
    window.location = "/panel/designapp/";
  };
  const GoLast = () => {
    setPageNum(PageNum - 1);
  };
  const CheckDeleteMusic = (el) => {
    let Del = el.target.checked;
    setDeleteMusic(Del);
  };
  const { t } = useTranslation();
  return (
    <>
      {PageNum === 2 ? (
        LoadPlayable === true ? (
          <Loading />
        ) : (
          <PlayableData.Provider value={playable}>
            <CreateFolderName.Provider value={mainurl}>
              <ReplaceAsset
                havemusic={havemusic}
                data1={playable}
                data2={mainurl}
                child={
                  <p className="primary-btn" onClick={RedirecttoChooseMusic}>
                    {t(NextPage)}
                  </p>
                }
                child2={
                  <p className="reverce-primary-btn" onClick={GoDessignApp}>
                    {t(LastPage)}
                  </p>
                }
              />
            </CreateFolderName.Provider>
          </PlayableData.Provider>
        )
      ) : PageNum === 3 ? (
        <PlayableData.Provider value={playable}>
          <CreateFolderName.Provider value={mainurl}>
            <ChooseMusic
              havemusic={havemusic}
              data={mainurl}
              data2={playable}
              child={
                <p className="primary-btn" onClick={GoChangeLink}>
                  {t(NextPage)}
                </p>
              }
              child2={
                <p className="reverce-primary-btn" onClick={GoLast}>
                  {t(LastPage)}
                </p>
              }
              /* child3={
               
              } */
            />
          </CreateFolderName.Provider>
        </PlayableData.Provider>
      ) : PageNum === 4 ? (
        <PlayableData.Provider value={playable}>
          <CreateFolderName.Provider value={mainurl}>
            <ChooseLink
              havemusic={havemusic}
              data={mainurl}
              data2={playable}
              child={
                <p className="primary-btn" onClick={Pay}>
                  {t(NextPage)}
                </p>
              }
              child2={
                <p className="reverce-primary-btn" onClick={GoLast}>
                  {t(LastPage)}
                </p>
              }
            />
          </CreateFolderName.Provider>
        </PlayableData.Provider>
      ) : PageNum === 5 ? (
        <PlayableData.Provider value={playable}>
          <CreateFolderName.Provider value={mainurl}>
            <Payment
              havemusic={havemusic}
              data={mainurl}
              data2={playable}
              child={
                <p className="primary-btn" onClick={AddUserPlayable}>
                  {t(PayBtnText)}
                </p>
              }
              child2={
                <p className="reverce-primary-btn" onClick={GoLast}>
                  {t(LastPage)}
                </p>
              }
            />
          </CreateFolderName.Provider>
        </PlayableData.Provider>
      ) : PageNum === 6 ? (
        <PlayableData.Provider value={playable}>
          <CreateFolderName.Provider value={mainurl}>
            <DesignExport
              havemusic={havemusic}
              data={mainurl}
              data2={playable}
              child={<p onClick={AddUserPlayable}>{t(PayBtnText)}</p>}
            />
          </CreateFolderName.Provider>
        </PlayableData.Provider>
      ) : (
        <Loading />
      )}
      {ErrorAlert === true && loadingstate === false ? (
        <ErrorPopUp ErrorText={errMsg} />
      ) : null}
      {loadingstate === true ? <Loading /> : null}
    </>
  );
};

export default DesignApp;
