import React, { useEffect, useState } from "react";
import "./FileUpload.css";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const FileUplaod = (props) => {
  const LangState = useSelector((state) => state.LangState.lang);

    useEffect(() => {
    const theCookie = Cookies.get("i18next");
    setlang(theCookie);
  }, []);
  const [lang, setlang] = useState();
  const { t } = useTranslation();
  return (
    <div className="custom-file mb-3 mt-3">
      <input
        type="file"
        className="custom-file-input"
        id={t(props.Id)}
        onChange={props.Change}
        multiple
      />
      <label
        id={props.id}
        className={
          LangState === "fa"
            ? "custom-file-label"
            : "custom-file-label custom-file-en"
        }
        for={t(props.Id)}
      >
        {t(props.lable)}
      </label>
    </div>
  );
};

export default FileUplaod;
