import React, { useState, useEffect } from "react";
import { BaseUrl, GetFactorwithClientRefId } from "../../../Texts/ApiUrl";
import Loading from "../../../Tools/Loading/Loading";
import { CallApi } from "../../CallApi/CallApi";
import FaildPay from "./FaildPay";
import "./Pay.css";
import SuccessPay from "./SuccessPay";
const Redirection = () => {
  const search = window.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  var clientrefId = params.get("clientRefId");
  const [isPay, setisPay] = useState(false);
  const [Preload, setPreload] = useState(true);
  const [Factor, setFactor] = useState(true);
  const CheckPay = async () => {
    if (clientrefId != null) {
      var Url =
        BaseUrl + GetFactorwithClientRefId + "?clientRefId=" + clientrefId;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("UserToken"));
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({});

      const result = await CallApi(Url, raw, myHeaders, "GET");
      console.log(result);
      if (result[1] === 200) {
        let data = result[0].data;
        return data;
      }
    }
  };
  useEffect(() => {
    const Result = async () => {
      try {
        let data = await CheckPay();
        if (data.verified === true) {
          setFactor(data);
          setisPay(true);
        }
      } catch {}
      setPreload(false);
    };
    Result();
  }, []);

  return (
    <div>
      {Preload === true ? (
        <Loading />
      ) : (
        <div className="status-box p-5">
          <div className="container-fluid">
            <div className="row">
              {isPay === true ? (
                <SuccessPay refId={Factor.clientRefId} />
              ) : (
                <FaildPay />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Redirection;
