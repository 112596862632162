import React, { Component } from 'react';
import LeftNav from '../LeftNav/LeftNav'

class Search extends Component {

    state = {
        header: [
            { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        ],
        Body: [
            {}
        ]

    }
    render() {
        return (
            <>
                <LeftNav />
                <div className="dashboard-panel">
                
                </div>

            </>
        );
    }
}

export default Search;