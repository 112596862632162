import React from "react";
import "./profile.css";
import Header from "../../ProfleHeader/ProfileHeader";
import ChangePasswordForm from "./ChangePasswordForm";
import { ProfileMenuItemText } from "../../../../Texts/Text";
import LeftNav from "../../../LeftNav/LeftNav";
const ChangePassword = () => {
  return (
    <div>
      <Header title={ProfileMenuItemText} />
      <LeftNav />
      <div className="dashboard-panel box-panel">
        <div>
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
