const initiailState = {
  Data: [],
  Loading: false,
  DataExist: false,
};
const PlayableReducer = (state = initiailState, action) => {
  switch (action.type) {
    case "REQUEST":
      return {
        ...state,
        Loading: true,
      };
    case "SUCCESS":
      return {
        ...state,
        Loading: false,
        Data: action.payload,
        DataExist: true,
      };
    default:
      return state;
  }
};
export default PlayableReducer;
