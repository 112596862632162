import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { PieChartTitle } from "../../../Texts/Text";
import { PlayableStatisticData } from "../ProfileBody/ProfileBody";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const PieChart = (props) => {
  const [ChartData, setChartData] = useState();
  /*   const [lang, setlang] = useState(); */
  const StatisticData = useContext(PlayableStatisticData);
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    useEffect(() => {
    /* const theCookie = Cookies.get("i18next");
    setlang(theCookie); */
  }, []);
  return (
    <div className="col-md-4 mt-1 p-2">
      <div className="dark-box p-3">
        <div className="chart-title">
          <h3>{props.title}</h3>
        </div>
        {lang === "fa" ? (
          <Doughnut
            data={props.data}
            options={{
              responsive: true,
              title: {
                display: false,
                fontSize: 20,
              },
              legend: {
                display: true,
                position: "right",
              },
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                  margin: "100",
                  align: "end",
                  labels: {
                    padding: 15,
                    usePointStyle: true,
                    // This more specific font property overrides the global property
                    font: {
                      family: "IRANSansWeb",
                    },
                  },
                },
                tooltip: {
                  bodyFont: {
                    family: "IRANSansWeb",
                  },
                },
              },
            }}
          />
        ) : (
          <Doughnut
            data={props.data}
            options={{
              responsive: true,
              title: {
                display: false,
                fontSize: 20,
              },
              legend: {
                display: true,
                position: "right",
              },
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                  margin: "100",
                  align: "start",
                  labels: {
                    padding: 15,
                    usePointStyle: true,
                    // This more specific font property overrides the global property
                    font: {
                      family: "Roboto",
                    },
                  },
                  tooltip: {
                    bodyFont: {
                      family: "Roboto",
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
      <div></div>
    </div>
  );
};

export default PieChart;
