import React, { Component } from 'react';
import './RemoveAsset.css'
import {
    RemoveAssetText,
  } from "../../../../Texts/Text";
class RemoveAsset extends Component {
    render() { 
        return <div className="remove-asset mt-2">
             <p onClick={this.props.Click}>
              <i className="fa fa-trash" aria-hidden="true"></i>
              {RemoveAssetText}
            </p>
        </div>
    }
}
 
export default RemoveAsset;