import React, { useState, useEffect } from "react";
import "./FullScreen.css";
const FullScreenGame = (props) => {
  return (
    <>
      <div className="loading-shadow">
        <div className="container p-4">
          <div className="row ">
            <div id="FullScreenGameIframe" className="col-12 mt-0 auto">
              {props.close}
              {props.child}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FullScreenGame;
