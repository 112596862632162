import React, { useState } from "react";
import "./form.css";
import FormHead from "../FormHead/FormHead";
import Field from "../../Tools/Inputs/input";
import {
  EmailLable,
  PasswordLable,
  LoginHeadText,
  EmailNotFoundText,
  PasswordInvalidText,
  error,
  ForgetPassText,
  LoginBtnText,
  LoginwithGoogleText,
  LoginwithEmailFormTitle,
  ChooseCreateAcount,
  QuestiontextForCreateAcount,
  EmailPlaceholderText,
  PasswordPlaceholderText,
} from "../../Texts/Text";
import { CallApi } from "../../PanelComponent/CallApi/CallApi";
import ErrorPopUp from "../../ErrorPopUp/ErrorPopUp";
import Loading from "../../Tools/Loading/Loading";
import {
  LoginUser,
  BaseUrl,
  ProfilrInfo,
  LoginWithGoogleRequest,
} from "../../Texts/ApiUrl";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import "../../../node_modules/flag-icon-css/css/flag-icons.min.css";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangeLang } from "../../Redux/lang/LangAction";
const Login = () => {
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const dispatch = useDispatch();
  const LoginAction = async () => {
    localStorage.removeItem("FastRegisterEmail");
    setloadingstate(true);
    var email = document.getElementById("Email");
    var password = document.getElementById("Password");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email.value,
      password: password.value,
      /* mobile: "09223911485",
      fullName: "حسین خسروی",
      jobTitle: "1",
      webSite: null, */
    });
    console.log(raw);
    const result = await CallApi(BaseUrl + LoginUser, raw, myHeaders, "POST");
    console.log(result);
    if (result[1] === 200) {
      localStorage.setItem("UserToken", result[0].data);
      //localStorage.setItem('UserEmail', result[0].data);
      let userData = await GetProfile();
      setErrorAlert(false);
      setloadingstate(false);
      if (userData.playableObjects.length === 0) {
        window.location = "/panel/designapp/";
      } else {
        window.location = "/panel/dashboard";
      }
    } else if (result[1] === 403) {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(PasswordInvalidText);
    } else if (result[1] === 404) {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(EmailNotFoundText);
    } else {
      setloadingstate(false);
      setErrorAlert(true);
      seterrMsg(error);
    }
  };
  const GetProfile = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");

    if (result[1] === 200) {
      let data = result[0].data;
      return data;
    } else {
      window.location = "/panel/login";
    }
  };
  const Changelang = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(ChangeLang(lang));
    if (lang === "en") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "ltr");
      r.style.setProperty("--align", "left");
      r.style.setProperty("--float-right", "left");
      r.style.setProperty("--float-left", "right");
      r.style.setProperty("--reverce-direction", "rtl");
      r.style.setProperty("--font", "Roboto,IRANSansWeb");
    }
    if (lang === "fa") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "rtl");
      r.style.setProperty("--align", "right");
      r.style.setProperty("--float-right", "right");
      r.style.setProperty("--float-left", "left");
      r.style.setProperty("--reverce-direction", "ltr");
      r.style.setProperty("--font", "Roboto,IRANSansWeb");
    }
    /*  window.location.reload(); */
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="col-lg-8 col-md-8 col-12 mt-3"></div>
      <div className="col-lg-6 col-md-8 col-12 mt-3 p-0 form auto login-form">
        <h1 className="title-form">{t(LoginHeadText)}</h1>
        <div className="form-body pt-5 pb-5">
          <form>
            <div className="row">
              <div className="col-md-12">
                <a
                  className="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                  href={BaseUrl + LoginWithGoogleRequest}
                >
                  <img src="https://img.icons8.com/color/16/000000/google-logo.png" />
                  {t(LoginwithGoogleText)}
                </a>
              </div>
            </div>
            <div className="or-container mt-5">
              <div className="line-separator"></div>
              <div className="or-label">{t(LoginwithEmailFormTitle)}</div>
              <div className="line-separator"></div>
            </div>
            <Field
              Required={true}
              Id="Email"
              aria="emailHelp"
              type="text"
              lable={EmailLable}
              placeholder={EmailPlaceholderText}
            />
            <Field
              Required={true}
              Id="Password"
              type="password"
              lable={PasswordLable}
              placeholder={PasswordPlaceholderText}
            />
            <Link to="/panel/forget-password" className="fotget-pass-text">
              {t(ForgetPassText)}
            </Link>
            <a onClick={LoginAction} className="primary-btn mt-3 auto">
              {t(LoginHeadText)}
            </a>
            <div className="choose-action">
              <p>{t(QuestiontextForCreateAcount)}</p>
              <Link to="/panel/register/">{t(ChooseCreateAcount)}</Link>
            </div>
            {ErrorAlert === true && loadingstate === false ? (
              <ErrorPopUp ErrorText={errMsg} />
            ) : null}
            {loadingstate === true ? <Loading /> : null}
          </form>
        </div>
        <div>
         {/*  <Dropdown> TODO: delete in the future or add German language
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <i className="fa fa-globe" aria-hidden="true"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => Changelang("fa")}>
                <span className="flag-icon flag-icon-ir"></span>فارسی
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => Changelang("en")}>
                {" "}
                <span className="flag-icon flag-icon-gb"></span>English
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>*/
          }
        </div>
      </div>
    </>
  );
};

export default Login;
