import React, { useState, useEffect } from "react";
import "./ProfileHeader.css";
import { AccountSettingText } from "../../../Texts/Text";
import { BaseUrl, ProfilrInfo } from "../../../Texts/ApiUrl";
import { CallApi } from "../../CallApi/CallApi";
import Loading from "../../../Tools/Loading/Loading";
import logo from "./title.png";
import { Dropdown } from "react-bootstrap";
import "../../../../node_modules/flag-icon-css/css/flag-icons.min.css";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import ExitPopUp from "../../../ErrorPopUp/ExitPopUp";
import { useSelector, useDispatch } from "react-redux";
import { ChangeLang } from "../../../Redux/lang/LangAction";
const Header = (props) => {
  const [TheUser, setTheUser] = useState();
  const [prelaodData, setprelaodData] = useState(true);
  const [ActiveExitPopUp, setActiveExitPopUp] = useState(false);
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

    const dispatch = useDispatch();
  useEffect(() => {
    const Loaddata = async () => {
      await GetProfile();
      setprelaodData(false);
    };

    Loaddata();
  }, []);
  const Changelang = (lang) => {
    dispatch(ChangeLang(lang));
    i18next.changeLanguage(lang);
    if (lang === "en") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "ltr");
      r.style.setProperty("--align", "left");
      r.style.setProperty("--float-right", "left");
      r.style.setProperty("--float-left", "right");
      r.style.setProperty("--reverce-direction", "rtl");
      r.style.setProperty("--font", "Roboto,IRANSansWeb");
    }
    if (lang === "fa") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "rtl");
      r.style.setProperty("--align", "right");
      r.style.setProperty("--float-right", "right");
      r.style.setProperty("--float-left", "left");
      r.style.setProperty("--reverce-direction", "ltr");
      r.style.setProperty("--font", "IRANSansWeb");
    }
    /* window.location.reload(false); */
  };
  const GetProfile = async () => {
    var Url = BaseUrl + ProfilrInfo;
    var myHeaders = new Headers();
    myHeaders.append("Token", localStorage.getItem("UserToken"));

    var raw = JSON.stringify({});
    const result = await CallApi(Url, raw, myHeaders, "GET");
    if (result[1] === 200) {
      let data = result[0].data;
      setTheUser(data);
    } else {
      window.location = "/panel/login";
    }
  };
  const logout = () => {
    setprelaodData(true);
    localStorage.removeItem("UserToken");
    setInterval(() => {
      window.location = "/panel/login";
    }, 2000);
  };
  const CancelExit = () => {
    setActiveExitPopUp(false);
  };
  const ShowPupUp = () => {
    setActiveExitPopUp(true);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="profile-head">
        <div className="profile-info">
          <h1>{t(props.title)}</h1>
          <div>
        {   /* <Dropdown> TODO: delete in the future or add German language
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className="fa fa-globe" aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => Changelang("fa")}>
                  <span className="flag-icon flag-icon-ir"></span>فارسی
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => Changelang("en")}>
                  {" "}
                  <span className="flag-icon flag-icon-gb"></span>English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>*/ }
            <a className="log-out" onClick={ShowPupUp}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>
            </a>
            <a href="/panel/dashboard">
              <img src={logo} alt="logo" />
            </a>
          </div>
        </div>
        {ActiveExitPopUp === true ? (
          <ExitPopUp
            exit={
              <a onClick={logout} className="reverce-primary-btn">
                {t("Exit")}
              </a>
            }
            cancel={
              <a onClick={CancelExit} className="primary-btn ">
                {t("Cancel")}
              </a>
            }
          />
        ) : null}
        {prelaodData === true ? <Loading /> : null}
      </div>
    </>
  );
};

export default Header;
