import React from "react";
import gif from "./Loading3.gif";
import "./Loading.css";
const Loading = () => {
  return (
    <div className="loading-gif">
      <img src={gif} alt="loading" />
    </div>
  );
};

export default Loading;
