import React, { useEffect, useState } from "react";
import "./form.css";
import FormHead from "../FormHead/FormHead";
import Field from "../../Tools/Inputs/input";
import {
  EmailLable,
  PasswordLable,
  RegisterHeadText,
  EmailExisterrorText,
  EmailInvaliderrorText,
  error,
  priorityDropDownLableText,
  FullNameRegisterText,
  PhoneNumberFieldLable,
  WebSiteAddressFieldLable,
  SubjectRegisterOptionLable,
  ErrorReqiredField,
  LoginwithGoogleText,
  RegisterwithEmailFormTitle,
  IhaveAcount,
  EmailPlaceholderText,
  PasswordPlaceholderText,
} from "../../Texts/Text";
import { CallApi } from "../../PanelComponent/CallApi/CallApi";
import ErrorPopUp from "../../ErrorPopUp/ErrorPopUp";
import Loading from "../../Tools/Loading/Loading";
import {
  BaseUrl,
  LoginWithGoogleRequest,
  RegisterUser,
} from "../../Texts/ApiUrl";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import "../../../node_modules/flag-icon-css/css/flag-icons.min.css";
import i18next from "i18next";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { ChangeLang } from "../../Redux/lang/LangAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const Register = () => {
  const [ErrorAlert, setErrorAlert] = useState();
  const [errMsg, seterrMsg] = useState();
  const [loadingstate, setloadingstate] = useState(false);
  const [phonNum, setphonNum] = useState(null);
  const [FullName, setFullName] = useState(null);
  const [subject, setsubject] = useState(null);
  const [webAdders, setwebAdders] = useState("");
  const [Email, setsEmail] = useState(null);
  const [Pass, setpass] = useState(null);
  /*  const [lang, setlang] = useState(null); */
  const [ShowPass, setShowPass] = useState("password");
{/*  const lang = useSelector((state) => state.LangState.lang);TODO:For just English 4 feb 2023 berlin */}
const lang ="en";

const dispatch = useDispatch();

  useEffect(() => {
    /*  const theCookie = Cookies.get("i18next");
    setlang(theCookie); */
    const search = window.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    var email = params.get("email");
    if (email !== null) {
      document.getElementById("Email").value = email;
    }
  }, []);
  const RegisterAction = async () => {
    setloadingstate(true);
    localStorage.removeItem("FastRegisterEmail");
    if (phonNum != null && Email != null && FullName != null) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: Email,
        fullName: FullName,
        jobTitle: "",
        mobile: phonNum,
        webSite: webAdders,
        password: Pass,
      });
      console.log(raw);
      const result = await CallApi(
        BaseUrl + RegisterUser,
        raw,
        myHeaders,
        "POST"
      );
      console.log(result);
      if (result[1] === 200) {
        localStorage.setItem("UserEmail", result[0].data.email);
        setloadingstate(false);
        setErrorAlert(false);
        window.location = "/panel/verify";
      } else if (result[1] === 406) {
        setloadingstate(false);
        seterrMsg(EmailExisterrorText);
        setErrorAlert(true);
      } else if (result[1] === 400) {
        setloadingstate(false);
        seterrMsg(EmailInvaliderrorText);
        setErrorAlert(true);
      } else {
        setloadingstate(false);
        seterrMsg(error);
        setErrorAlert(true);
      }
    } else {
      setloadingstate(false);
      seterrMsg(ErrorReqiredField);
      setErrorAlert(true);
    }
  };
  const Changelang = (langChoose) => {
    i18next.changeLanguage(langChoose);
    dispatch(ChangeLang(langChoose));
    if (lang === "en") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "ltr");
      r.style.setProperty("--align", "left");
      r.style.setProperty("--float-right", "left");
      r.style.setProperty("--float-left", "right");
      r.style.setProperty("--reverce-direction", "rtl");
      r.style.setProperty("--font", "Roboto,IRANSansWeb");
    }
    if (lang === "fa") {
      var r = document.querySelector(":root");
      r.style.setProperty("--site-direction", "rtl");
      r.style.setProperty("--align", "right");
      r.style.setProperty("--float-right", "right");
      r.style.setProperty("--float-left", "left");
      r.style.setProperty("--reverce-direction", "ltr");
      r.style.setProperty("--font", "Roboto,IRANSansWeb");
    }
    /* window.location.reload(); */
  };
  const ChangePhoneNum = async (el) => {
    setphonNum(el.target.value);
  };
  const ChangeEmail = async (el) => {
    setsEmail(el.target.value);
  };
  const ChangePass = async (el) => {
    setpass(el.target.value);
  };
  const ChangeFulName = async (el) => {
    setFullName(el.target.value);
  };

  const ChangeWebAddress = async (el) => {
    setwebAdders(el.target.value);
  };
  const ChangePasswordType = async (el) => {
    if (ShowPass === "text") {
      setShowPass("password");
      el.target.classList = "fa fa-eye";
    } else {
      setShowPass("text");
      el.target.classList = "fa fa-eye-slash";
    }
  };
  const { t } = useTranslation();
  return (
    <div
      className="col-lg-6 col-md-8 col-12 mt-3
     p-0 form auto  login-form mb-3"
    >
      <h1 className="title-form">{t(RegisterHeadText)}</h1>
      <div className="form-body pt-5 pb-5">
        <form>
          <div className="row">
            <div className="col-md-12">
              <a
                className="btn btn-lg btn-google btn-block text-uppercase btn-outline"
                href={BaseUrl + LoginWithGoogleRequest}
              >
                <img src="https://img.icons8.com/color/16/000000/google-logo.png" />
                {t(LoginwithGoogleText)}
              </a>
            </div>
          </div>
          <div className="or-container mt-5">
            <div className="line-separator"></div>
            <div className="or-label">{t(RegisterwithEmailFormTitle)}</div>
            <div className="line-separator"></div>
          </div>

          <Field
            Id="FullName"
            type="text"
            lable={FullNameRegisterText}
            Required={true}
            placeholder="UserNamePlaceholder"
            onChange={(e) => ChangeFulName(e)}
          />
          <Field
            Id="Email"
            aria="emailHelp"
            type="text"
            lable={EmailLable}
            Required={true}
            placeholder={EmailPlaceholderText}
            onChange={(e) => ChangeEmail(e)}
          />
          <Field
            Id="PhoneNumber"
            type="text"
            placeholder="MobilePlaceholder"
            lable={PhoneNumberFieldLable}
            Required={true}
            onChange={(e) => ChangePhoneNum(e)}
          />
          <Field
            Id="Password"
            type={ShowPass}
            placeholder={PasswordPlaceholderText}
            lable={PasswordLable}
            Required={true}
            onChange={(e) => ChangePass(e)}
            icon={
              <i
                className={lang === "fa" ? "fa fa-eye" : "fa fa-eye en-eye"}
                aria-hidden="true"
                onClick={(e) => ChangePasswordType(e)}
              ></i>
            }
          />
          <Field
            Id="WebSiteAddress"
            type="text"
            lable={WebSiteAddressFieldLable}
            placeholder="WebUrlPlaceholder"
            onChange={(e) => ChangeWebAddress(e)}
          />
          <a onClick={RegisterAction} className="primary-btn register-mt auto">
            {t(RegisterHeadText)}
          </a>
          <div className="choose-action">
            <Link to="/panel/login/">{t(IhaveAcount)}</Link>
          </div>
          {ErrorAlert === true && loadingstate === false ? (
            <ErrorPopUp ErrorText={errMsg} />
          ) : null}
          {loadingstate === true ? <Loading /> : null}
        </form>
      </div>
      <div>
  { /*     <Dropdown> TODO: delete in the future or add German language
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-globe" aria-hidden="true"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => Changelang("fa")}>
              <span className="flag-icon flag-icon-ir"></span>فارسی
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => Changelang("en")}>
              {" "}
              <span className="flag-icon flag-icon-gb"></span>English
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        */
        }
      </div>
    </div>
  );
};
export default Register;
