import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./TimeLine.css";
import { useTranslation } from "react-i18next";
import {
  TimeLineChooseTemplateText,
  TimeLineReolaceAssetText,
  TimeLineMusicText,
  TimeLinePayment,
  TimeLineExport,
  SpecialLinkTimeLine,
} from "../../../Texts/Text";
const TimeLine = (props) => {
  const [PageNumber, setPageNumber] = useState(props.PageNum);
  useEffect(() => {
    let el = document.getElementById(PageNumber);
    el.className = "active-timeline";
    var otherPage = PageNumber - 1;
    for (let i = otherPage; i > 0; i--) {
      let el2 = document.getElementById(i);
      el2.style.backgroundColor = "var(--primary-green)";
      el2.childNodes[0].innerHTML =
        '<i class="fa fa-check" aria-hidden="true"></i>';
      el2.style.padding = "0px 0px";
      el2.style.color = "white";
    }
  }, [PageNumber]);
  const { t } = useTranslation();
  return (
    <div className="pt-1 site-direction">
      <div className="progress-container">
        <div className="progress" id="progress"></div>
        <a id="1">
          <span>1</span>
          <p>{t(TimeLineChooseTemplateText)}</p>
        </a>
        <a id="2">
          <span>2</span>
          <p>{t(TimeLineReolaceAssetText)}</p>
        </a>
        <a id="3">
          <span>3</span>
          <p>{t(TimeLineMusicText)}</p>
        </a>
        <a id="4">
          <span>4</span>
          <p>{t(SpecialLinkTimeLine)}</p>
        </a>
        <a id="5">
          <span>5</span>
          <p>{t(TimeLinePayment)}</p>
        </a>
        <a id="6">
          <span>6</span>
          <p>{t(TimeLineExport)}</p>
        </a>
      </div>
    </div>
  );
};

export default TimeLine;
